<template >
			<div class="fancy-feature-seventeen position-relative mt-160 xl-mt-50">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-xl-6 col-lg-5" data-aos="fade-right">
							<div class="title-style-three text-center text-lg-start">
								<h2 class="main-title">نقدم خدماتنا بطريقة سهلة ومضمونة</h2>
							</div> <!-- /.title-style-three -->
						</div>
						<div class="col-xl-6 col-lg-7" data-aos="fade-left">
							<p class="m0 text-center text-lg-start md-pt-30">نساعدك في شراء ما تحتاج إليه بسهولة وبطريقة بسيطة ومرنة </p>
						</div>
					</div>

					<div class="row justify-content-center pt-30">
						<div class="col-lg-4 col-md-6" data-aos="fade-right">
							<div class="block-style-twelve mt-30 ps-lg-0 pe-xl-5 me-xl-2">
								<div class="icon d-flex align-items-end"><img src="/frontend/images/icon/icon_31.svg" alt=""></div>
								<h5><a href="#">التخزين</a></h5>
								<p>نقدم لك خدمة تجميع المشتريات لتضمن أن تصلك دفعة واحدة </p>
								<a href="#" class="tran3s more-btn go-to"><img src="/frontend/images/icon/icon_20.svg" alt=""></a>
							</div> <!-- /.block-style-twelve -->
						</div> <!-- /.item -->
						<div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
							<div class="block-style-twelve mt-30 active">
								<div class="icon d-flex align-items-end"><img src="/frontend/images/icon/icon_32.svg" alt=""></div>
								<h5><a href="#">نجدة</a></h5>
								<p>موجودون معك عندما تطلبنا وعند حاجتك .</p>
								<a href="#" class="tran3s more-btn go-to"><img src="/frontend/images/icon/icon_20.svg" alt=""></a>
							</div> <!-- /.block-style-twelve -->
						</div> <!-- /.item -->
						<div class="col-lg-4 col-md-6" data-aos="fade-left">
							<div class="block-style-twelve mt-30">
								<div class="icon d-flex align-items-end"><img src="/frontend/images/icon/icon_33.svg" alt=""></div>
								<h5><a href="#">إعادة تغليف أمنة</a></h5>
								<p>تغليف المنتجات بطريقة مضمونة لحمايتها من التلف.</p>
								<a href="#" class="tran3s more-btn go-to"><img src="/frontend/images/icon/icon_20.svg" alt=""></a>
							</div> <!-- /.block-style-twelve -->
						</div> <!-- /.item -->
					</div>
				</div> <!-- /.container -->
				<div class="shapes shape-one"></div>
			</div> <!-- /.fancy-feature-seventeen -->
</template>

<script>
export default {

name: 'Services',
        
    };

</script>