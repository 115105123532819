<template>

        <banner-section> </banner-section>

        <services-section></services-section>
			<!-- 
			=============================================
				Feature Section One
			============================================== 
			-->
            <feature-one></feature-one>
			<!-- 
			=============================================
				Feature Section Tow
			============================================== 
			-->
			<!-- /.fancy-feature-nineteen -->
             <feature-Tow></feature-Tow>
			<!--
			=====================================================
				Skills Section 
			=====================================================
			-->
	        <skills-section></skills-section>
			<!--
			=====================================================
				Feedback Slider 
			=====================================================
			-->
			<reviews-section></reviews-section>
			<!--
			=====================================================
				Faq Section Twenty
			=====================================================
			-->
			<faq-section></faq-section>
			<!--
			=====================================================
				Blog Section Three
			=====================================================
			-->
		    <blog-section></blog-section>
			<!--
			=====================================================
				Contact Section 
			=====================================================
			-->
		    <contact-section></contact-section>
			<!--
			=====================================================
				Partner Section 
			=====================================================
			-->
			<partener-section></partener-section>
			<!--
			=====================================================
				News Letter Section
			=====================================================
			-->

</template>


<script>

import BannerSection from '../../components/HomePage/Banner.vue';
import ServicesSection from '../../components/HomePage/Services.vue';
import FeatureOne from '../../components/HomePage/FeatureOne.vue';
import FeatureTow from '../../components/HomePage/FeatureTow.vue';
import SkillsSection from '../../components/HomePage/Skills.vue';
import ReviewsSection from '../../components/HomePage/Reviews.vue';
import FaqSection from '../../components/HomePage/Faq.vue';
import BlogSection from '../../components/HomePage/Blog.vue';
import ContactSection from '../../components/HomePage/Contact.vue';
import PartenerSection from '../../components/HomePage/Partener.vue';
import { createDOMCompilerError } from '@vue/compiler-dom';



export default {
   name: "HomePage",
	mounted() {
    if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
    } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
    }
},
    components: { 
	              BannerSection,
				  ServicesSection,
				   FeatureOne,
				   FeatureTow,
				   SkillsSection,
				   ReviewsSection,
				   FaqSection,
				   BlogSection,
				   ContactSection,
				   PartenerSection,

				    }, 
    };

</script>