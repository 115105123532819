<template >

<Sign-In-page></Sign-In-page>

</template>

<script>
import  SignInPage from '../../components/Pages/SignIn.vue';

export default {
   name: "SignIn",
    components: { 
        SignInPage,
				    }, 
    };

</script>