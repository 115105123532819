<template >

<Portfolio-page></Portfolio-page>

</template>

<script>
import PortfolioPage from '../../components/Pages/Portfolio.vue';

export default {
   name: "Portfolio",
    components: { 
        PortfolioPage,
				    }, 
    };

</script>