<template >
<br> <br> <br> 
<div class="user-data-page clearfix d-md-flex">
				<div class="illustration-wrapper d-none d-md-flex align-items-center">
					<h3>احصل على جميع رغباتك وأنت في مكانك</h3>
					<div class="illustration-holder">
						<img src="images/assets/ils_22.svg" alt="" class="illustration m-auto">
					</div>
				</div> <!-- /.illustration-wrapper -->

				<div class="form-wrapper">
					<div class="d-flex justify-content-between align-items-center">
						<div class="logo"><a href="index.html" class="d-block"><img src="images/logo/logo_01.png" alt="" width="131"></a></div>
					</div>
					<form  @submit.prevent="submit" class="user-data-form mt-60 lg-mt-40">
						<h2>مرحبا بك !</h2>
						<p class="header-info pt-20 pb-20 lg-pt-10 lg-pb-10">هل لديك حساب ?  <router-link :to="{ name: 'SignIn' }" role="button"> تسجيل الدخول</router-link> </p>


						<div class="row">
							<div class="col-12">
								<div id="response"></div>
								<div class="input-group-meta mb-20">
									<label>الاسم الكامل*</label>
									<input type="text" required name="name" placeholder="الرجاء ادخال الاسم الكامل">
								</div>
							</div>
							<div class="col-12">
								<div class="input-group-meta mb-20">
									<label>البريد الالكتروني*</label>
									<input type="email" reuired name="email" placeholder="example@mail.com">
								</div>
							</div>
							<div class="col-12">
								<div class="input-group-meta mb-20">
									<label>كلمة المرور*</label>
									<input type="password" name="password" required placeholder="الرجاء ادخال كلمة المرور" class="pass_log_id">
									<span class="placeholder_icon"><span class="passVicon"><img src="images/icon/icon_40.svg" alt=""></span></span>
								</div>
							</div>
							<div class="col-12">
								<div class="input-group-meta mb-20">
									<label>تأكيد كلمة المرور*</label>
									<input type="password" name="c_password" required placeholder="الرجاء ادخال كلمة المرور" class="pass_log_id">
									<span class="placeholder_icon"><span class="passVicon"><img src="images/icon/icon_40.svg" alt=""></span></span>
								</div>
							</div>
							<div class="col-12">
								<div class="agreement-checkbox d-flex justify-content-between align-items-center sm-mt-10">
									<div>
										<input type="checkbox"  id="agree_to_policy">
										<label for="agree_to_policy" >بالنقر فوق "الأشتراك" ، تكون قد قبلت وتوافق على الشروط والأحكام وسياسة الخصوصية في موقعنا .</label>
									</div>
								</div> <!-- /.agreement-checkbox -->
							</div>
							<div class="col-12">
								<button class="btn-eight w-100 mt-50 mb-40 lg-mt-30 lg-mb-30" >الأشتراك</button>
							</div>
							<div class="col-12">							</div>
						</div>
					</form>
				</div> <!-- /.form-wrapper -->
			</div> <!-- /.user-data-page -->



</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {

name: 'SignUp',
        
    setup(){
        const router=useRouter();
        const submit=async e =>{
		const form =new FormData(e.target);
		const inputs=Object.fromEntries(form.entries());
		
		const response=await axios.post('register',inputs)
			
	  .catch((error)=>{

		    console.log('error',error)
			if(error.response.data.data.email!=null)
           document.getElementById('response').innerHTML="هذا البريد الإلكتروني مستخدم سابقاً ..الرجاء تغيير البريد الإلكتروني";
		   else if(error.response.data.data.c_password !=null)
		     document.getElementById('response').innerHTML="يرجى التحقق من تأكيد كلمة المرور بشكل صحيح"
		   document.getElementById('response').style.background="#edb2ad";

	  });
			 
    
		 
			// axios.defaults.headers.common['Authorization'] =  'Bearer ' + response.data.data.token;
			// localStorage.setItem('token',response.data.data.token);
			// console.log('Bearer ' + response.data.data.token);
	
	

		axios.defaults.headers.common['Authorization'] =  'Bearer ' + response.data.data.token;
		localStorage.setItem('token',response.data.data.token);
		console.log('Bearer ' + response.data.data.token);
		await router.push('/AddProfile');
	  }

	  return {
		submit
	  }
    },
  
   

};
</script>