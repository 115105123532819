<template >

<Add-Profile-page></Add-Profile-page>

</template>

<script>
import  AddProfilePage from '../../components/Pages/AddProfile.vue';

export default {
   name: "AddProfile",
    components: { 
        AddProfilePage,
				    }, 
    };

</script>