<template >
	<div class="blog-section-three position-relative pt-70 lg-pt-40">
				<div class="container">
					<div class="title-style-three text-center mb-50 lg-mb-20" data-aos="fade-up">
						<div class="sc-title"></div>
						<h2 class="main-title">المدونة</h2>
					</div> <!-- /.title-style-three -->

					<div class="row gx-xxl-5">
						<div class="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" v-for="blog in blogs" :key="blog.id">
							<article class="blog-meta-one color-two tran3s mt-30">
								<figure class="post-img m0">
									<img src="/frontend/images/blog/blog_img_01.webp" alt="" class="w-100 tran4s">
									<router-link class="w-100 d-block" :to="{ name: 'BlogDetile',params:{id:blog.id} }"  role="button"></router-link>
									</figure>
								<div class="post-data">
									<div class="post-tag">
										<router-link  :to="{ name: 'BlogDetile',params:{id:blog.id} }"  role="button">مقالات</router-link>
								   </div>
								<router-link class="blog-title" :to="{ name: 'BlogDetile',params:{id:blog.id} }"  role="button"> <h5>  {{ blog.title }} </h5></router-link>
								</div> <!-- /.post-data -->
							</article>
						</div>


					</div>
				</div>
			</div> <!-- /.blog-section-three -->
</template>


<script>
import axios from 'axios';

export default {
name: 'Bloge', 

data() {
		return {
			blogs: [],
		}
	},

	mounted() {
		this.get_blogs();
	},


	methods: {

		get_blogs() {

                 axios.get("blogs")
	                .then((response) => {
		                this.blogs = response.data.data;
	                })
	                .catch((error) => {
	        	       console.log(error)
	                })

       },


	}


 };

	

</script>