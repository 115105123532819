<template >

<Add-Order-page></Add-Order-page>

</template>

<script>
import  AddOrderPage from '../../components/Pages/AddOrder.vue';

export default {
   name: "AddOrder",
    components: { 
        AddOrderPage,
				    }, 
    };

</script>