<template >
<div class="fancy-feature-nineteen position-relative pt-130 lg-pt-80">
				<div class="container">
					<div class="row">
						<div class="col-xxl-5 col-lg-6 col-md-7">
							<div class="block-style-thirteen" data-aos="fade-right">
								<div class="title-style-three pb-15">
									<div class="sc-title"></div>
									<h2 class="main-title">ما يميزنا</h2>
								</div> <!-- /.title-style-three -->
								<ul class="style-none list-item">
									<li data-aos="fade-up">
										<div class="numb tran3s">1</div>
										<h6>الشحن</h6>
										<span>نعمل على شحن بضائعك لتصل إلى باب منزلك.</span>
									</li>
									<li data-aos="fade-up" data-aos-delay="50">
										<div class="numb tran3s">2</div>
										<h6>الأمان والضمان</h6>
										<span>نبذل أقصى ما لدينا لتصل شحناتك سليمة وبدون أي ضرر .</span>
									</li>
									<li data-aos="fade-up" data-aos-delay="100">
										<div class="numb tran3s">3</div>
										<h6>اختيار الطلبات التي ترغب بشحنها</h6>
										<span>فبدلاً من شحن جميع الطلبات يمكنك اختيار عدد معين من الطلبات والتي قد تكون أكثر ضرورية من غيرها .</span>
									</li>
								</ul>
							</div> <!-- /.block-style-thirteen -->
						</div>
					</div>
				</div> <!-- /.container -->
				<div class="illustration-holder" data-aos="fade-left">
					<img src="/frontend/images/assets/24851.webp" alt="" >
					<!--  
					<img src="/frontend/images/assets/ils_15_1.svg" alt="" class="shapes shape-one">
					<img src="/frontend/images/assets/ils_15_2.svg" alt="" class="shapes shape-two">
					<img src="/frontend/images/assets/ils_15_3.svg" alt="" class="shapes shape-three">
					<img src="/frontend/images/assets/ils_15_4.svg" alt="" class="shapes shape-four">
					<img src="/frontend/images/assets/ils_15_5.svg" alt="" class="shapes shape-five" data-aos="fade-down" data-aos-delay="200" data-aos-duration="2000">
					<img src="/frontend/images/assets/ils_15_6.svg" alt="" class="shapes shape-six" data-aos="fade-down" data-aos-delay="100" data-aos-duration="2000">
					<img src="/frontend/images/assets/ils_15_7.svg" alt="" class="shapes shape-seven" data-aos="fade-down" data-aos-duration="2000">
					-->
				</div> <!-- /.illustration-holder -->
				<div class="shapes oval-one"></div>
				<div class="shapes oval-two"></div>
				<img src="/frontend/images/shape/shape_35.svg" alt="" class="shapes bg-shape">
			</div> 
</template>

<script>
export default {

name: 'FeatureTow',
        
    };

</script>