<template >

<Dash-Board-page></Dash-Board-page>

</template>

<script>
import  DashBoardPage from '../../components/Pages/DashBoard.vue';

export default {
   name: "DashBoard",
    components: { 
        DashBoardPage,
				    }, 
    };

</script>