
<template >
	<div class="theme-inner-banner">
				<div class="container">
					<h2 class="intro-title text-center">حول الشركة</h2>
					<ul class="page-breadcrumb style-none d-flex justify-content-center">
						<li><a href="index.html">الرئيسية</a></li>
						<li class="current-page">>حول الشركة</li>
					</ul>
				</div>
				<img src="images/shape/shape_38.svg" alt="" class="shapes shape-one">
				<img src="images/shape/shape_39.svg" alt="" class="shapes shape-two">
			</div> <!-- /.theme-inner-banner -->



			<!-- 
			=============================================
				Feature Section Two
			============================================== 
			-->
			<div class="fancy-feature-two position-relative mt-140 lg-mt-100">
				<div class="container">
					<div class="row">
						<div class="col-xxl-6 col-lg-6 col-md-7 ms-auto">
							<div class="block-style-two" data-aos="fade-left">
								<div class="title-style-one">
									<!-- <div class="sc-title-four">حول الشركة</div> -->
									<h3>حول الشركة</h3>

									<h2 class="main-title" style="color: #00A651;"> المارد التركي </h2>
								</div> <!-- /.title-style-one -->
								<p class="pt-10 pb-20 lg-pb-10 about-txt">يحقق المارد التركي للأشخاص خارج تركيا فرصة تجربة الشراء من أهم الماركات التركية وأشهرها وتجميع مشترياتهم في مكان واحد لشحنها بتكلفة أقل وبطريقة مناسبة.
تتركز خدمات المارد التركي في استقبال ما يرغب العميل بشرائه من المواقع التركية وطلبه وتجميعه في صندوق خاص بالعميل لدى المارد التركي وحين تُكمل المشتريات طرداً كاملاً يقوم المارد بشحنها للعميل في بلده.
غاية المارد التركي هي توفير تجربة شراء مريحة وتوفيرية للأشخاص من تركيا يستطيعون من خلالها التسوق بلا حدود أو قيود على الكمية أو النوعية، وبذلك يكون العميل في مكانه وكأنه في تركيا يقوم بالتسوق والاستفادة من كل العروض والمواسم وشحنها له بالكيفية التي يريد.</p>
								 <router-link class="btn-one mt-30 mb-4" :to="{ name: 'ContactUs' }" role="button">اتصل بنا</router-link>
							</div> <!-- /.block-style-two -->
						</div>
					</div>
				</div> <!-- /.container -->
				<div class="illustration-holder-two sm-mt-40 about-m">
					<img src="/frontend/images/logo/Turk-cini.png" alt="" class="main-illustration w-100 logo-about shape-one">
				</div> <!-- /.illustration-holder-two -->
			</div> <!-- /.fancy-feature-two -->
</template>

<script>
export default {

name: 'About',
        
    };

</script>