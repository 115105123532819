<template >
<div class="fancy-feature-twentyOne mt-200 pb-100 lg-mt-120 lg-pb-50">
				<div class="container">
					<div class="row">
						<div class="col-lg-6 col-md-7 ms-auto">
							<div class="block-style-five ps-xxl-5" data-aos="fade-left">
								<div class="title-style-three">
									<div class="sc-title"></div>
									<h2 class="main-title">كُنْ مستعداّ لعيش تجربة جديدة ممتعة .</h2>
								</div> <!-- /.title-style-three -->
								<p class="pt-20 pb-15">نسعى دائماً لإيجاد طريقة سهلة ومرنة تحصل من خلالها على ما تريد، ونعمل جاهدين على أن تكون تجربة لا مثيل لها من خلالها تضمن حصولك على جميع رغباتك وأن في مكانك..</p>
								<router-link class="btn-eight ripple-btn" :to="{ name: 'ContactUs' }" role="button">اتصل بنا</router-link> 
							</div> <!-- /.block-style-five -->
						</div>
					</div>
				</div>
				<div class="illustration-holder" data-aos="fade-right">
					<img src="/frontend/images/assets/render-online.webp" alt="" class="w-100 main-illustration">
				</div> <!-- /.illustration-holder -->
				<img src="/frontend/images/shape/shape_37.svg" alt="" class="shapes shape-one">
				<div class="shapes oval-one"></div>
				<div class="shapes oval-two"></div>
			</div> <!-- /.fancy-feature-twentyOne -->
</template>

<script>
export default {

name: 'Contact',
        
    };

</script>