<template >

<Blog-Detile-Page></Blog-Detile-Page>

</template>

<script>
import  BlogDetilePage from '../../components/Pages/BlogDetile.vue';

export default {
   name: "BlogDetile",
    components: { 
        BlogDetilePage,

				    }, 
  }

</script>