<template >
<div class="feedback-section-five pt-130 lg-pt-100 pb-95 lg-pb-40">
				<div class="container">
					<div class="title-style-three text-center" data-aos="fade-up">
						<div class="sc-title"></div>
						<h2 class="main-title">آراء العملاء</h2>
					</div> <!-- /.title-style-three -->
					<carousel @next="next"
					@prev="prev">
						<carousel-slide v-for="(slide,index) in slides "
						:key="slide"
						:index="index"
						:visibleSlide="visibleSlide" 
						:direction="direction">
						                                
						<div class="col-xxl-9 col-xl-10 col-lg-8 m-auto">
									<div class="feedback-block-four mb-80 ms-xxl-4 me-xxl-4">
										<img src="/frontend/images/icon/icon_34.svg" alt="" class="m-auto">
										<p>{{slide.paragraph}}</p>
										<div class="cp-info">
											<h6>{{slide.name}} </h6>
											<span></span>
										</div>
									</div> <!-- /.feedback-block-four -->
								</div>
						</carousel-slide>
					
					</carousel>
					

	</div>
</div>
				<img src="/frontend/images/media/img_08.jpg" alt="" class="shapes avatar-one" width="45" height="45" style="outline-width: 6px;">
				<img src="/frontend/images/media/img_09.jpg" alt="" class="shapes avatar-two" width="85" height="85" style="outline-width: 10px;">
				<img src="/frontend/images/media/img_10.jpg" alt="" class="shapes avatar-three" width="85" height="85" style="outline-width: 10px;">
				<img src="/frontend/images/media/img_11.jpg" alt="" class="shapes avatar-four" width="50" height="50" style="outline-width: 5px;">
			
</template>

<script>
import Carousel from './Carousel.vue';
import CarouselSlide from './CarouselSlide.vue';
export default {
	data(){
		return{
			slides:[
			{"paragraph":"لقد استفدت كثيراً من هذا الموقع وقد وفر علي عناء الذهاب إلى السوق.",
			"name":"نتالي"
			},
			{"paragraph":"لم أتوقع وجود طريقة أحصل فيها على ما أريد وأنا في منزلي.",
			"name":"كريم"
			},
			{"paragraph":"حصلت على منتجات بنوعية جيدة وأسعار مناسبة.",
			"name":"سها"
			},
		],
		visibleSlide:0,
		direction:'left'
		}
	},
	components:{
		Carousel,
		CarouselSlide
	},
	computed:{
		slidesLen()
		{
			return this.slides.length;
		}
	},
	methods:{
		next(){
			if(this.visibleSlide >= this.slidesLen-1){
				this.visibleSlide=0;
			}
			else{
				this.visibleSlide++;
			}
			this.direction="left";
		},
		prev(){
			if(this.visibleSlide <= 0){
				this.visibleSlide=this.slidesLen-1;
			}
			else{
				this.visibleSlide--;
			}
			this.direction="right";
		}
	},
	mounted: function () {
  window.setInterval(() => {
    this.next()
  }, 10000)
}
}

</script>