<template>
	<div class="main-page-wrapper">

		<!-- 
			=============================================
				Portfolio Gallery Three
			============================================== 
			-->
		<div class="portfolio-gallery-three mt-140 mb-150 lg-mt-90 lg-mb-100">
			<div class="container">
				<ul class="style-none text-center isotop-menu-wrapper g-control-nav-one pb-30 lg-pb-10 dash-head">
					<li v-on:click="get_products()">طرودي</li>
					<li v-on:click="show_shipping_boxes()">صناديق الشحن </li>
					<li v-on:click="show_shipped_shipping_boxes()">صناديق الشحن السابقة</li>
					<li v-on:click="show_my_address_in_turkey()">عنواني في تركيا</li>
					<li v-on:click="show_deliver_addresses()">عناوينك المسجلة</li>
					<li v-on:click="show_transactions()">معاملاتك المالية</li>
				</ul>

				<div id="isotop-gallery-wrapper">
					<div class="grid-sizer"></div>
					<div class="isotop-item stock">

					</div> <!-- /.item -->
					<div class="isotop-item dev mobile">
						<div class="md-stepper-horizontal orange">

						</div>

					</div> <!-- /.item -->
				</div>
				<!-- products -->
				<div v-if="show == 1" class="md-stepper-horizontal main_dashboard_div">
					<router-link class="nav-link left" :to="{ name: 'AddOrder' }" role="button">إضافة طلب طرد جديد</router-link>

					<table class="table table-hover table-responsive-sm dashboard_table" id="mytable">
						<thead>
							<tr>
								<th> #</th>
								<th> اسم المادة</th>
								<th> الصنف </th>
								<th> الوزن [kg]</th>
								<th> الحجم </th>
								<th> السعر </th>
								<th> العملة </th>
								<th> صورة المنتج</th>

							</tr>
						</thead>
						<tbody>
							<tr v-for="product in products" :key="product.id">
								<td><input type="checkbox" v-on:change="get_total()" name="product_id"></td>
								<td>{{ product.name }}</td>
								<td>{{ product.category_id }}</td>
								<td>{{ product.weight }}</td>
								<td>{{ product.volume }}</td>
								<td>{{ product.price }}</td>
								<td>{{ product.currency_id }}</td>

								<!-- <td><img v-if="product.image != null"
										v-bind:src="'https://turkey-cenie.com/uploads/product/' + product.image" alt=""
										class="w-100 table-img"></td> -->

								<td><img v-if="product.image != null"
								v-bind:src="'/uploads/product/' + product.image" alt=""
								class="w-100 table-img"></td>

								<td style="display:none"><input id="product_id" type="hidden" v-bind:value=product.id></td>

							</tr>


						</tbody>
					</table>

					<table class="table table-hover table-responsive-sm border_table " style="border=border">
						<tr>
							<!-- <td>الإجمـــالـي</td> -->
							<td> </td>
							<td class ="mx-4">الــــوزن: {{ products_weight }}</td>
							<td></td>
							<td>الحــــجــــم: {{ products_volume }}</td>
							<td> </td>
							<td>الســـــعر: {{ products_price }}</td>
							<td></td>

						</tr>
					</table>

					<PopUp v-if="popupTriggers.buttonTrigger" :TogglePopup="() => TogglePopup('buttonTrigger')">
						<h4>إضافة الطرود إلى صندوق الشحن</h4>
						<div id="shipping_box_response"></div>
						<table class="table table-hover table-responsive-sm center_table">
							<thead>
								<tr>

									<th> اسم المادة</th>
									<th> الصنف </th>
									<th> الوزن [kg]</th>
									<th> الحجم </th>
									<th> السعر </th>
									<th> العملة </th>


								</tr>
							</thead>
							<tbody>
								<tr v-for="product in checked_products" :key="product.id">

									<td>{{ product.name }}</td>
									<td>{{ product.category_id }}</td>
									<td>{{ product.weight }}</td>
									<td>{{ product.volume }}</td>
									<td>{{ product.price }}</td>
									<td>{{ product.currency_id }}</td>


								</tr>


							</tbody>
						</table>
						<form action="#" @submit.prevent="submit_shippingbox()" id="submit_shippingbox"
							class="user-data-form mt-60 lg-mt-40">
							<div class="col-6">
								<div class="input-group-meta mb-25">
									<label>اختر عنوان التوصيل</label>
									<select name="address_id" class="form-select form-select-lg mb-6">
										<option v-for="deliver_address in deliver_addresses" :key="deliver_address.id"
											v-bind:value="deliver_address.id">
											{{ deliver_address.country }}-{{ deliver_address.state }}-{{ deliver_address.city }}
										</option>
									</select>
									<input type="hidden" name="user_id" v-bind:value="user_id">
									<input type="hidden" name="total_weight" v-bind:value="products_weight">
									<input type="hidden" name="total_volume" v-bind:value="products_volume">
									<input type="hidden" name="price" v-bind:value="products_price">
									<input type="hidden" name="currency_id" v-bind:value="products_price">

								</div>
							</div>
							<br>

							<button class="btn btn-success left-btn" id="add_to_shippingbox" type="submit">إضافة إلى الصندوق
							</button>
						</form>
					</PopUp>


					<button class="btn btn-success popup-btn" @click="() => TogglePopup('buttonTrigger')">
						إضافة إلى صندوق الشحن
					</button>
				</div>







				<div v-if="show == 2" class="user-data-page clearfix d-md-flex md-stepper-horizontal main_dashboard_div">

					<div class="illustration-wrapper d-none d-md-flex align-items-center side_dashboard_div">
						<h3 id="list-hover"> صناديق الشحن</h3>
						<ul class="list-group deliver_address_list">
							<div @mouseover="mouseOver" @mouseout="mouseOut">
								<li class="list-group-item title">
									<i class="fa fa-angle-down" aria-hidden="true"></i>
									صناديق الشحن الخاصة بي
								</li>
								<div class="hide-menu" id="hide-item-menu">
									<ul class="list-group">
										<li class="list-group-item hidden " v-for="shipping_box in shipping_boxes"
											:key="shipping_box.id" @click="get_shipping_box_by_id(shipping_box.id)">صندوق
											رقم: #{{ shipping_box.id }}</li>
									</ul>
								</div>
							</div>
						</ul>
						<div class="illustration-holder">
							<img src="images/assets/ils_22.svg" alt="" class="illustration m-auto">
						</div>
					</div> <!-- /.illustration-wrapper -->


					<div class="md-stepper-horizontal horizontal">
						<div class="d-flex justify-content-between align-items-center">
							<div class="logo"><a href="index.html" class="d-block"><img src="images/logo/logo_01.png" alt=""
										width="131"></a></div>
						</div>

						<div class="user-data-form mt-60 lg-mt-40">
							<div class="row">
								<h4> معلومات الصندوق رقم #{{ shipping_box.id }}:</h4>
								<div class="border-div">

									<label>حالة الصندوق:</label>
									<div v-if="shipping_box.status == 'packing'" class="isotop-item dev mobile">
										<div class="md-stepper-horizontal orange">
											<div class="md-step active done">
												<div class="md-step-circle"><span>1</span></div>
												<div class="md-step-title">قيد التجهيز <i class="bi bi-check"></i></div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step  editable">
												<div class="md-step-circle"><span>2</span></div>
												<div class="md-step-title">الدفع </div>
												<!-- <div class="md-step-optional">Optional</div> -->
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step">
												<div class="md-step-circle"><span>3</span></div>
												<div class="md-step-title">قيد الشحن</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step">
												<div class="md-step-circle"><span>4</span></div>
												<div class="md-step-title">مشحون</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
										</div>
									</div>






									<div v-else-if="shipping_box.status == 'payment'" class="isotop-item dev mobile">
										<div class="md-stepper-horizontal orange">
											<div class="md-step active done">
												<div class="md-step-circle"><span>1</span></div>
												<div class="md-step-title">قيد التجهيز</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step active editable">
												<div class="md-step-circle"><span>2</span></div>
												<div class="md-step-title">الدفع<i class="bi bi-check"></i></div>
												<!-- <div class="md-step-optional">Optional</div> -->
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step ">
												<div class="md-step-circle"><span>3</span></div>
												<div class="md-step-title">قيد الشحن</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step">
												<div class="md-step-circle"><span>4</span></div>
												<div class="md-step-title">مشحون</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
										</div>
									</div>




									<div v-else-if="shipping_box.status == 'pending'" class="isotop-item dev mobile">
										<div class="md-stepper-horizontal orange">
											<div class="md-step active done">
												<div class="md-step-circle"><span>1</span></div>
												<div class="md-step-title">قيد التجهيز</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step active editable">
												<div class="md-step-circle"><span>2</span></div>
												<div class="md-step-title">الدفع</div>
												<!-- <div class="md-step-optional">Optional</div> -->
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step active">
												<div class="md-step-circle"><span>3</span></div>
												<div class="md-step-title">قيد الشحن<i class="bi bi-check"></i></div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step">
												<div class="md-step-circle"><span>4</span></div>
												<div class="md-step-title">مشحون</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
										</div>
									</div>




									<div v-else-if="shipping_box.status == 'shipped'" class="isotop-item dev mobile">
										<div class="md-stepper-horizontal orange">
											<div class="md-step active done">
												<div class="md-step-circle"><span>1</span></div>
												<div class="md-step-title">قيد التجهيز</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step active editable">
												<div class="md-step-circle"><span>2</span></div>
												<div class="md-step-title">الدفع</div>
												<!-- <div class="md-step-optional">Optional</div> -->
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step active">
												<div class="md-step-circle"><span>3</span></div>
												<div class="md-step-title">قيد الشحن</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step active">
												<div class="md-step-circle"><span>4</span></div>
												<div class="md-step-title">مشحون<i class="bi bi-check"></i></div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
										</div>
									</div>


									<div class="row">
										<div class="col-6">
											<div class="input-group-meta mb-20">
												<label>الوزن: </label>
												<div class="shadow_span">{{ shipping_box.total_weight }}</div>
											</div>
										</div>

										<div class="col-6">
											<div class="input-group-meta mb-20">
												<label>الحجم:</label>
												<div class="shadow_span">{{ shipping_box.total_volume }}</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-3">
											<div class="input-group-meta mb-20">
												<label>سعر الطرود:</label>
												<div class="shadow_span">{{ shipping_box.price }}</div>
											</div>
										</div>

										<div class="col-3">
											<div class="input-group-meta mb-20">
												<label>كلفة الشحن:</label>
												<div class="shadow_span">{{ shipping_box.shipping_price }}</div>
											</div>
										</div>

										<div class="col-3">
											<div class="input-group-meta mb-20">
												<label>الإجمـــالـي:</label>
												<div class="shadow_span">{{ this.shipping_box_ammount }}</div>
											</div>
										</div>

										<div class="col-3">
											<div class="input-group-meta mb-20">
												<label>العــملة:</label>
												<div class="shadow_span"></div>
											</div>




											<PopUp v-if="popupTriggers.buttonTrigger"
												:TogglePopup="() => TogglePopup('buttonTrigger')">
												<h4> تثبيت عملية الدفع </h4>
												<div id="payment_response"></div>
												<table class="table table-hover table-responsive-sm center_table">
													<thead>
														<tr>

															<th> سعر الطرود </th>
															<th> كلفة الشحن </th>
															<th> الإجمالي</th>
															<th> العملة </th>



														</tr>
													</thead>
													<tbody>
														<tr>
															<td>{{ shipping_box.price }}</td>
															<td>{{ shipping_box.shipping_price }}</td>
															<td>{{ this.shipping_box_ammount }}</td>
															<td></td>
														</tr>


													</tbody>
												</table>
												<form action="#" @submit.prevent="submit_payment()" id="submit_payment"
													class="user-data-form mt-60 lg-mt-40">
													<div class="row">
														<div class="col-6">
															<h4>رصيدي الحالي :</h4>
															<div class="shadow_span">{{ shipping_box.user.profile.balance }}
															</div>
														</div>
														<div class="col-6">
															<h4>العملة :</h4>
															<div class="shadow_span"></div>
															<input type="text" hidden="hidden" name="user_id"
																v-bind:value="shipping_box.user.id">
															<input type="text" hidden="hidden" name="shipping_box_id"
																v-bind:value="shipping_box.id">
															<input type="text" hidden="hidden" name="date"
																v-bind:value="currentDate()">
															<input type="text" hidden="hidden" name="currency_id"
																v-bind:value="1">
														</div>
													</div>
													<br>
													<button v-if="shipping_box.shipping_price != null"
														class="btn btn-success left-btn" id="payment_button"
														type="submit">تثبيت عملية الدفع</button>

												</form>
											</PopUp>




										</div>

									</div>
									<div class="row">
										<div class="col-3"> </div>
										<div v-if="shipping_box.status == 'packing'" class="col-6">
											<button v-if="shipping_box.shipping_price != null"
												class=" btn-eight w-100 mt-50 mb-40 lg-mt-30 lg-mb-30 btn btn-success main-btn"
												@click="() => TogglePopup('buttonTrigger')">
												تثبيت عملية الدفع
											</button>

											<button v-else-if="shipping_box.shipping_price == null" disabled
												class=" btn-eight w-100 mt-50 mb-40 lg-mt-30 lg-mb-30 btn btn-success main-btn">
												تثبيت عملية الدفع
											</button>
										</div>
									</div>
								</div>
								<div class="border-div margin-top-div">
									<div class="row">

										<h5>الطرود المسجلة ضمن الصندوق:</h5>
										<table class="table table-hover table-responsive-sm dashboard_table" id="mytable">
											<thead>
												<tr>
													<th> اسم المادة</th>
													<th> الصنف </th>
													<th> الوزن [kg]</th>
													<th> الحجم </th>
													<th> السعر </th>
													<th> العملة </th>
													<th> صورة المنتج</th>

												</tr>
											</thead>
											<tbody>
												<tr v-for="product in shipping_box.products" :key="product.id">
													<td>{{ product.name }}</td>
													<td>{{ product.category_id }}</td>
													<td>{{ product.weight }}</td>
													<td>{{ product.volume }}</td>
													<td>{{ product.price }}</td>
													<td>{{ product.currency_id }}</td>

													<td><img v-if="product.image != null"
															v-bind:src="'/uploads/product/' + product.image"
															alt="" class="w-100 table-img"></td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>


							<br>

							<div class="border-div">
								<h5>عنوان التوصيل</h5>
								<div class="row" v-for="deliver_address in shipping_box.address" :key="deliver_address.id">
									<div class="col-3">

										<div class="input-group-meta mb-25">
											<label>الـــدولة: </label>
											<div class="shadow_span">{{ deliver_address.country }}</div>
										</div>
									</div>

									<div class="col-3">
										<div class="input-group-meta mb-25">
											<label>الولاية</label>
											<div class="shadow_span">{{ deliver_address.state }}</div>
										</div>
									</div>
									<div class="col-3">
										<div class="input-group-meta mb-25">
											<label>المدينة</label>
											<div class="shadow_span">{{ deliver_address.city }}</div>
										</div>
									</div>
									<div class="col-3">
										<div class="input-group-meta mb-25">
											<label>المنطقة</label>
											<div class="shadow_span">{{ deliver_address.region }}</div>
										</div>
									</div>
									<div class="col-6">
										<div class="input-group-meta mb-25">
											<label>اسم العنوان</label>
											<div class="shadow_span">{{ deliver_address.address_name }}</div>
										</div>
									</div>
									<div class="col-6">
										<div class="input-group-meta mb-25">
											<label>العنوان</label>
											<div class="shadow_span">{{ deliver_address.address }}</div>
										</div>
									</div>
									<div class="col-6">
										<div class="input-group-meta mb-25">
											<label>رقم الهاتف</label>
											<div class="shadow_span">{{ deliver_address.telephone }}</div>
										</div>
									</div>
									<div class="col-6">
										<div class="input-group-meta mb-25">
											<label>الجوال</label>
											<div class="shadow_span">{{ deliver_address.mobile }}</div>
										</div>
									</div>
									<div class="col-3">
										<div class="input-group-meta mb-25">
											<label>اسم الشركة</label>
											<div class="shadow_span">{{ deliver_address.company_name }}</div>
										</div>
									</div>
									<div class="col-3">
										<div class="input-group-meta mb-25">
											<label>الرمز البريدي</label>
											<div class="shadow_span">{{ deliver_address.postcode }}</div>
										</div>
									</div>
									<div class="col-3">
										<div class="input-group-meta mb-25">
											<label>الدائرة الضريبية</label>
											<div class="shadow_span">{{ deliver_address.tax_department }}</div>
										</div>
									</div>
									<div class="col-3">
										<div class="input-group-meta mb-25">
											<label>الرقم الضريبي</label>
											<div class="shadow_span">{{ deliver_address.tax_number }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<!-- <div class="col-3"></div>
								<div class="col-6">
									<button class="btn-eight w-100 mt-50 mb-40 lg-mt-30 lg-mb-30" @click="edit_deliver_address()" type="submit"> تعديل
										العنوان</button>
								</div> -->

								<div class="col-12">
									
								</div>
							</div>

							<!-- <div id="edit_address_response"></div> -->

						<!-- <form v-if="showDeliverAddress == 2" action="#" @submit.prevent="submit_update_deliver_address()"
							id="submit_update_deliver_address" class="user-data-form mt-60 lg-mt-40">
							<h4> تعديل العنوان</h4>


							<div class="row">

								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الدولة</label>
										<select name="country" class="form-select form-select-lg mb-6" required="required">
											<option v-for="country in countries" :key="country.id"
												v-bind:value="country.name">
												{{ country.name }}
											</option>
										</select>
									</div>
								</div>

								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الولاية</label>
										<input type="text" name="state" placeholder="أدخل الولاية">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>المدينة</label>
										<input type="text" name="city" required="required" placeholder="أدخل المدينة">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>المنطقة</label>
										<input type="text" name="region" placeholder="أدخل المنطقة">
									</div>
								</div>
								<div class="col-12">
									<div class="input-group-meta mb-25">
										<label>اسم العنوان</label>
										<input type="text" name="address_name" placeholder="أدخل اسم العنوان">
									</div>
								</div>
								<div class="col-12">
									<div class="input-group-meta mb-25">
										<label>العنوان</label>
										<input type="text" name="address" required="required " placeholder="أدخل العنوان">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>رقم الهاتف</label>
										<input type="text" name="telephone" placeholder="أدخل الهاتف">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الجوال</label>
										<input type="text" name="mobile" placeholder="أدخل الجوال">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>اسم الشركة</label>
										<input type="text" name="company_name" placeholder="أدخل اسم الشركة">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الرمز البريدي</label>
										<input type="text" name="postcode" placeholder="أدخل الرمز البريدي">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الدائرة الضريبية</label>
										<input type="text" name="tax_department" placeholder="أدخل الدائرة الضريبية">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الرقم الضريبي</label>
										<input type="text" name="tax_number" placeholder="أدخل الرقم الضريبي">
									</div>
								</div> -->

								<PopUp v-if="popupTriggers.buttonTrigger_deliver_address" :TogglePopup="() => TogglePopup('buttonTrigger_deliver_address')">
						           
								   <form action="#" @submit.prevent="submit_update_shipping_boxes_deliver_address()"
									   id="submit_update_shipping_boxes_deliver_address" class="user-data-form mt-60 lg-mt-40">
									   
									   <h4> تعديل العنوان</h4>

									    <div id="edit_shipping_boxes_address_response"></div>

											   <div class="input-group-meta mb-25">
												   <label>اختر عنوان التوصيل الجديد : </label>
												   <select name="address_id" class="form-select form-select-lg mb-6">
									               	    <option v-for="deliver_address in deliver_addresses" :key="deliver_address.id"
									                   		v-bind:value="deliver_address.id">
									                   		{{ deliver_address.country }} - {{ deliver_address.state }} - {{ deliver_address.city }}
									                   	</option>
									               </select>
											   </div>

									   <button class="btn btn-success left-btn w-40"  id="edit_shipping_boxes_address_deliver_button" type="submit">
										   تعديل العنوان  
									   </button>
								   </form>
							   </PopUp>

								<div class="col-12" v-if="if_shipping_box_under_shipment != 'pending' && if_shipping_box_under_shipment != 'shipped'">
									<!-- <button class="btn-eight w-100 mt-50 mb-40 lg-mt-30 lg-mb-30" id="edit_address_deliver_button"  @click="edit_deliver_address_2()" type="submit"> تعديل العنوان </button> -->

									<button class="btn btn-success"  @click="() => TogglePopup('buttonTrigger_deliver_address')">
						                تعديل العنوان   
					                </button>
								</div>
								<div class="col-12">
								</div>
							</div>
						<!-- </form> -->

						</div>


					</div> <!-- /.form-wrapper -->
				</div>












				<div v-if="show == 3" class="user-data-page clearfix d-md-flex md-stepper-horizontal main_dashboard_div">

					<div class="illustration-wrapper d-none d-md-flex align-items-center side_dashboard_div">

						<h3 id="list-hover"> صناديق الشحن السابقة</h3>
						<ul class="list-group deliver_address_list">
							<div @mouseover="mouseOver" @mouseout="mouseOut">
								<li class="list-group-item title">
									<i class="fa fa-angle-down" aria-hidden="true"></i>
									الصناديق المشحونة
								</li>
								<div class="hide-menu" id="hide-item-menu">
									<ul class="list-group">
										<li class="list-group-item hidden" v-for="shipping_box in shipped_shipping_boxes"
											:key="shipping_box.id" @click="get_shipping_box_by_id(shipping_box.id)">صندوق
											رقم: #{{ shipping_box.id }}</li>
									</ul>
								</div>
							</div>
						</ul>
						<div class="illustration-holder">
							<img src="images/assets/ils_22.svg" alt="" class="illustration m-auto">
						</div>
					</div> <!-- /.illustration-wrapper -->


					<div class="md-stepper-horizontal horizontal">
						<div class="d-flex justify-content-between align-items-center">
							<div class="logo"><a href="index.html" class="d-block"><img src="images/logo/logo_01.png" alt=""
										width="131"></a></div>
						</div>

						<div class="user-data-form mt-60 lg-mt-40">
							<div class="row">
								<h4> معلومات الصندوق رقم #{{ shipping_box.id }}:</h4>
								<div class="border-div">

									<div class="row">
										<div class="col-6">
											<div class="input-group-meta mb-20">
												<label>الوزن بالكيلوغرام: </label>
												<div class="shadow_span">{{ shipping_box.total_weight }}</div>
											</div>
										</div>

										<div class="col-6">
											<div class="input-group-meta mb-20">
												<label>الحجم بالمتر المكعب:</label>
												<div class="shadow_span">{{ shipping_box.total_volume }}</div>
											</div>
										</div>
										<div class="col-4">
											<div class="input-group-meta mb-20">
												<label>سعر الطرود:</label>
												<div class="shadow_span">{{ shipping_box.price }}</div>
											</div>
										</div>

										<div class="col-4">
											<div class="input-group-meta mb-20">
												<label>كلفة الشحن:</label>
												<div class="shadow_span">{{ shipping_box.shipping_price }}</div>
											</div>
										</div>

										<div class="col-4">
											<div class="input-group-meta mb-20"> 
												<label> الإجمالي:</label>

												<div class="shadow_span">{{ shipping_box_ammount }}</div>
											</div>
										</div>

									</div>
									<label>حالة الصندوق:</label>
									<div v-if="shipping_box.status == 'packing'" class="isotop-item dev mobile">
										<div class="md-stepper-horizontal orange">
											<div class="md-step active done">
												<div class="md-step-circle"><span>1</span></div>
												<div class="md-step-title">قيد التجهيز <i class="bi bi-check"></i></div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step  editable">
												<div class="md-step-circle"><span>2</span></div>
												<div class="md-step-title">الدفع </div>
												<!-- <div class="md-step-optional">Optional</div> -->
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step">
												<div class="md-step-circle"><span>3</span></div>
												<div class="md-step-title">قيد الشحن</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step">
												<div class="md-step-circle"><span>4</span></div>
												<div class="md-step-title">مشحون</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
										</div>
									</div>






									<div v-else-if="shipping_box.status == 'payment'" class="isotop-item dev mobile">
										<div class="md-stepper-horizontal orange">
											<div class="md-step active done">
												<div class="md-step-circle"><span>1</span></div>
												<div class="md-step-title">قيد التجهيز</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step active editable">
												<div class="md-step-circle"><span>2</span></div>
												<div class="md-step-title">الدفع<i class="bi bi-check"></i></div>
												<!-- <div class="md-step-optional">Optional</div> -->
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step ">
												<div class="md-step-circle"><span>3</span></div>
												<div class="md-step-title">قيد الشحن</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step">
												<div class="md-step-circle"><span>4</span></div>
												<div class="md-step-title">مشحون</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
										</div>
									</div>




									<div v-else-if="shipping_box.status == 'pending'" class="isotop-item dev mobile">
										<div class="md-stepper-horizontal orange">
											<div class="md-step active done">
												<div class="md-step-circle"><span>1</span></div>
												<div class="md-step-title">قيد التجهيز</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step active editable">
												<div class="md-step-circle"><span>2</span></div>
												<div class="md-step-title">الدفع</div>
												<!-- <div class="md-step-optional">Optional</div> -->
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step active">
												<div class="md-step-circle"><span>3</span></div>
												<div class="md-step-title">قيد الشحن<i class="bi bi-check"></i></div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step">
												<div class="md-step-circle"><span>4</span></div>
												<div class="md-step-title">مشحون</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
										</div>
									</div>




									<div v-else-if="shipping_box.status == 'shipped'" class="isotop-item dev mobile">
										<div class="md-stepper-horizontal orange">
											<div class="md-step active done">
												<div class="md-step-circle"><span>1</span></div>
												<div class="md-step-title">قيد التجهيز</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step active editable">
												<div class="md-step-circle"><span>2</span></div>
												<div class="md-step-title">الدفع</div>
												<!-- <div class="md-step-optional">Optional</div> -->
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step active">
												<div class="md-step-circle"><span>3</span></div>
												<div class="md-step-title">قيد الشحن</div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
											<div class="md-step active">
												<div class="md-step-circle"><span>4</span></div>
												<div class="md-step-title">مشحون<i class="bi bi-check"></i></div>
												<div class="md-step-bar-left"></div>
												<div class="md-step-bar-right"></div>
											</div>
										</div>
									</div>



								</div>

								<div class="border-div margin-top-div">
									<div class="row">

										<h5>الطرود المسجلة ضمن الصندوق:</h5>
										<table class="table table-hover table-responsive-sm dashboard_table" id="mytable">
											<thead>
												<tr>
													<th> اسم المادة</th>
													<th> الصنف </th>
													<th> الوزن [kg]</th>
													<th> الحجم </th>
													<th> السعر </th>
													<th> العملة </th>
													<th> صورة المنتج</th>

												</tr>
											</thead>
											<tbody>
												<tr v-for="product in shipping_box.products" :key="product.id">
													<td>{{ product.name }}</td>
													<td>{{ product.category_id }}</td>
													<td>{{ product.weight }}</td>
													<td>{{ product.volume }}</td>
													<td>{{ product.price }}</td>
													<td>{{ product.currency_id }}</td>

													<!-- <td><img v-if="product.image != null"
															v-bind:src="'https://turkey-cenie.com/uploads/product/' + product.image"
															alt="" class="w-100 table-img"></td> -->

													<td>
														<img v-if="product.image != null"
													    v-bind:src="'/uploads/product/' + product.image"
													    alt="" class="w-100 table-img">
												    </td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>


							<br>

							<div class="border-div">
								<h5>عنوان التوصيل</h5>
								<div class="row" v-for="deliver_address in shipping_box.address" :key="deliver_address.id">
									<div class="col-3">

										<div class="input-group-meta mb-25">
											<label>الـــدولة: </label>
											<div class="shadow_span">{{ deliver_address.country }}</div>
										</div>
									</div>

									<div class="col-3">
										<div class="input-group-meta mb-25">
											<label>الولاية</label>
											<div class="shadow_span">{{ deliver_address.state }}</div>
										</div>
									</div>
									<div class="col-3">
										<div class="input-group-meta mb-25">
											<label>المدينة</label>
											<div class="shadow_span">{{ deliver_address.city }}</div>
										</div>
									</div>
									<div class="col-3">
										<div class="input-group-meta mb-25">
											<label>المنطقة</label>
											<div class="shadow_span">{{ deliver_address.region }}</div>
										</div>
									</div>
									<div class="col-6">
										<div class="input-group-meta mb-25">
											<label>اسم العنوان</label>
											<div class="shadow_span">{{ deliver_address.address_name }}</div>
										</div>
									</div>
									<div class="col-6">
										<div class="input-group-meta mb-25">
											<label>العنوان</label>
											<div class="shadow_span">{{ deliver_address.address }}</div>
										</div>
									</div>
									<div class="col-6">
										<div class="input-group-meta mb-25">
											<label>رقم الهاتف</label>
											<div class="shadow_span">{{ deliver_address.telephone }}</div>
										</div>
									</div>
									<div class="col-6">
										<div class="input-group-meta mb-25">
											<label>الجوال</label>
											<div class="shadow_span">{{ deliver_address.mobile }}</div>
										</div>
									</div>
									<div class="col-3">
										<div class="input-group-meta mb-25">
											<label>اسم الشركة</label>
											<div class="shadow_span">{{ deliver_address.company_name }}</div>
										</div>
									</div>
									<div class="col-3">
										<div class="input-group-meta mb-25">
											<label>الرمز البريدي</label>
											<div class="shadow_span">{{ deliver_address.postcode }}</div>
										</div>
									</div>
									<div class="col-3">
										<div class="input-group-meta mb-25">
											<label>الدائرة الضريبية</label>
											<div class="shadow_span">{{ deliver_address.tax_department }}</div>
										</div>
									</div>
									<div class="col-3">
										<div class="input-group-meta mb-25">
											<label>الرقم الضريبي</label>
											<div class="shadow_span">{{ deliver_address.tax_number }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<button class="btn-eight w-100 mt-50 mb-40 lg-mt-30 lg-mb-30" type="submit"> تعديل
										العنوان</button>
								</div>

								<div class="col-12">
								</div>
							</div>
						</div>


					</div> <!-- /.form-wrapper -->
				</div>












				<div v-if="show == 6" class="user-data-page clearfix d-md-flex md-stepper-horizontal main_dashboard_div">

					<div class="illustration-wrapper d-none d-md-flex align-items-center side_dashboard_div">
						<h3> معاملاتي المالية </h3>
						<ul class="list-group deliver_address_list">




						</ul>
						<div class="illustration-holder">
							<img src="images/assets/ils_22.svg" alt="" class="illustration m-auto">
						</div>
					</div> <!-- /.illustration-wrapper -->


					<div class="md-stepper-horizontal horizontal ">
						<div class="d-flex justify-content-between align-items-center">
							<div class="logo"><a href="index.html" class="d-block"><img src="images/logo/logo_01.png" alt=""
										width="131"></a></div>
						</div>

						<div class="user-data-form mt-60 lg-mt-40">
							<div class="row">
								<h4> رصيدي الحالي:</h4>
								<div class="border-div">

									<div class="row">
										<div class="col-5">
											<div class="input-group-meta mb-20">
												<label>المبلغ: </label>
												<div class="shadow_span">{{ balance }}</div>
											</div>
										</div>

										<div class="col-5">
											<div class="input-group-meta mb-20">
												<label>العملة:</label>
												<div class="shadow_span"></div>
											</div>
										</div>

									</div>


								</div>

								<div class="border-div margin-top-div">
									<div class="row">

										<h5>عمليات الإيداع الخاصة بي :</h5>
										<table class="table table-hover table-responsive-sm dashboard_table " id="mytable">
											<thead>
												<tr>
													<th> المبلغ</th>
													<th> العملة </th>
													<th> التاريخ </th>
													<th> الملاحظات </th>
													<th> الموظف المدخل </th>
													<th>المرفق</th>


												</tr>
											</thead>
											<tbody>
												<tr v-for="deposit in deposites" :key="deposit.id">
													<td>{{ deposit.ammount }}</td>
													<td>{{ deposit.currency }}</td>
													<td>{{ deposit.date }}</td>

													<td>{{ deposit.notes }}</td>
													<td>{{ deposit.employee }}</td>

													<!-- <td><img v-if="deposit.image != null"
															v-bind:src="'https://turkey-cenie.com/public/uploads/avatars/' + deposit.image"
															alt="" class="w-100 table-img"></td> -->

													<td>
														<img v-if="deposit.image != null"
													    v-bind:src="'/uploads/deposite/' + deposit.image"
													    alt="" class="w-100 table-img">
												    </td>

												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>


							<br>

							<div class="border-div">
								<h5>عمليات الدفع الخاصة بي </h5>
								<table class="table table-hover table-responsive-sm dashboard_table" id="mytable">
									<thead>
										<tr>
											<th> المبلغ</th>
											<th> العملة </th>
											<th> التاريخ </th>
											<th>صندوق الشحن رقم</th>


										</tr>
									</thead>
									<tbody>
										<tr v-for="payment in payments" :key="payment.id">
											<td>{{ payment.ammount }}</td>
											<td>{{ payment.currency }}</td>
											<td>{{ payment.date }}</td>
											<td>#{{ payment.shipping_box.id }}</td>



										</tr>
									</tbody>
								</table>

								<div class="col-12">
								</div>
							</div>
						</div>


					</div> <!-- /.form-wrapper -->
				</div>




				<div v-if="show == 4" class="user-data-page clearfix d-md-flex md-stepper-horizontal main_dashboard_div">
					<div>BARBAROS HAYRETTİN PAŞA MAH. 1996. SK. YALI KÖŞKÜ ABLOK NO: 2 A İÇ KAPI NO: 11 ESENYURT/ İSTANBUL
						Barbaros Hayrettin Paşa Mah Esenyurt/İstanbul</div>
				</div>

				<div v-if="show == 5" class="user-data-page clearfix d-md-flex md-stepper-horizontal main_dashboard_div">

					<div class="illustration-wrapper d-none d-md-flex align-items-center side_dashboard_div">
						<h3 id="list-hover">عناويني المسجلة</h3>
						<ul class="list-group deliver_address_list">
							<li class="list-group-item title" @click="add_deliver_address()">إضافة عنوان جديد </li>
							<div @mouseover="mouseOver" @mouseout="mouseOut">
								<li class="list-group-item title">
									<i class="fa fa-angle-down" aria-hidden="true"></i>
									عناويني المسجلة
								</li>
								<div class="hide-menu" id="hide-item-menu">
									<ul class="list-group deliver_address_list">
										<li class="list-group-item sidebar-item hidden"
											v-for="deliver_address in deliver_addresses" :key="deliver_address.id"
											@click="get_deliver_address_by_id(deliver_address.id)">
											{{ deliver_address.country }}</li>
									</ul>
								</div>
							</div>

						</ul>
						<div class="illustration-holder">
							<img src="images/assets/ils_22.svg" alt="" class="illustration m-auto">
						</div>
					</div> <!-- /.illustration-wrapper -->


					<div class="md-stepper-horizontal  horizontal">
						<div class="d-flex justify-content-between align-items-center">
							<div class="logo"><a href="index.html" class="d-block"><img src="images/logo/logo_01.png" alt=""
										width="131"></a></div>
						</div>

						<div id="add_address_response"></div>
						
						<form v-if="showDeliverAddress == 1" action="#" @submit.prevent="submit_deliver_address()"
							id="submit_deliver_address" class="user-data-form mt-60 lg-mt-40">
							<h4> إضافة عنوان جديد</h4>


							<div class="row">

								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الدولة</label>
										<select name="country" class="form-select form-select-lg mb-6" required="required">
											<option v-for="country in countries" :key="country.id"
												v-bind:value="country.name">
												{{ country.name }}
											</option>
										</select>
									</div>
								</div>

								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الولاية</label>
										<input type="text" name="state" placeholder="أدخل الولاية">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>المدينة</label>
										<input type="text" name="city" required="required" placeholder="أدخل المدينة">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>المنطقة</label>
										<input type="text" name="region" placeholder="أدخل المنطقة">
									</div>
								</div>
								<div class="col-12">
									<div class="input-group-meta mb-25">
										<label>اسم العنوان</label>
										<input type="text" name="address_name" placeholder="أدخل اسم العنوان">
									</div>
								</div>
								<div class="col-12">
									<div class="input-group-meta mb-25">
										<label>العنوان</label>
										<input type="text" name="address" required="required " placeholder="أدخل العنوان">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>رقم الهاتف</label>
										<input type="text" name="telephone" placeholder="أدخل الهاتف">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الجوال</label>
										<input type="text" name="mobile" placeholder="أدخل الجوال">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>اسم الشركة</label>
										<input type="text" name="company_name" placeholder="أدخل اسم الشركة">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الرمز البريدي</label>
										<input type="text" name="postcode" placeholder="أدخل الرمز البريدي">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الدائرة الضريبية</label>
										<input type="text" name="tax_department" placeholder="أدخل الدائرة الضريبية">
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الرقم الضريبي</label>
										<input type="text" name="tax_number" placeholder="أدخل الرقم الضريبي">
									</div>
								</div>
								<div class="col-12">
									<button class="btn-eight w-100 mt-50 mb-40 lg-mt-30 lg-mb-30" id="add_new_address_deliver_button" type="submit"> إضافة
										عنوان</button>
								</div>
								<div class="col-12">
								</div>
							</div>
						</form>

						<div v-else-if="showDeliverAddress == 0" class="user-data-form mt-60 lg-mt-40">
							<div class="row">
								<div id="delete_address_response"></div>
								<br>
								<h4> عناويني المسجلة</h4>
								<br>
								
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الـــدولة: </label>
										<div class="shadow_span">{{ deliver_address.country }}</div>
									</div>
								</div>

								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الولاية</label>
										<div class="shadow_span">{{ deliver_address.state }}</div>
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>المدينة</label>
										<div class="shadow_span">{{ deliver_address.city }}</div>
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>المنطقة</label>
										<div class="shadow_span">{{ deliver_address.region }}</div>
									</div>
								</div>
								<div class="col-12">
									<div class="input-group-meta mb-25">
										<label>اسم العنوان</label>
										<div class="shadow_span">{{ deliver_address.address_name }}</div>
									</div>
								</div>
								<div class="col-12">
									<div class="input-group-meta mb-25">
										<label>العنوان</label>
										<div class="shadow_span">{{ deliver_address.address }}</div>
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>رقم الهاتف</label>
										<div class="shadow_span">{{ deliver_address.telephone }}</div>
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الجوال</label>
										<div class="shadow_span">{{ deliver_address.mobile }}</div>
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>اسم الشركة</label>
										<div class="shadow_span">{{ deliver_address.company_name }}</div>
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الرمز البريدي</label>
										<div class="shadow_span">{{ deliver_address.postcode }}</div>
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الدائرة الضريبية</label>
										<div class="shadow_span">{{ deliver_address.tax_department }}</div>
									</div>
								</div>
								<div class="col-6">
									<div class="input-group-meta mb-25">
										<label>الرقم الضريبي</label>
										<div class="shadow_span">{{ deliver_address.tax_number }}</div>
									</div>
								</div>

							    <div class="col-6">
									<button class="btn-eight w-100 mt-50 mb-40 lg-mt-30 lg-mb-30" @click="edit_deliver_address()" type="submit">
										 تعديل العنوان
									</button>
								</div> 

								<div class="col-6">
									<button class="btn-danger w-100 mt-50 mb-40 lg-mt-30 lg-mb-30" id="delete_address_deliver_button" v-on:click="delete_address_deliver(deliver_address.id)">
										 حذف العنوان
									</button>
								</div>
								<div class="col-12">
								</div>
							</div>
						</div>


						<div v-if="showDeliverAddress == 2" class="user-data-form mt-60 lg-mt-40">
							
							<form action="#" @submit.prevent="submit_update_deliver_address()"
							      id="submit_update_deliver_address" class="user-data-form mt-60 lg-mt-40">
							    <h4> تعديل العنوان</h4>

								<div id="edit_address_response2"></div>

							    <div class="row">

							    	<div class="col-6">
							    		<div class="input-group-meta mb-25">
							    			<label>الدولة</label>
							    			<select name="country" class="form-select form-select-lg mb-6" required="required">
							    				<option v-for="country in countries" :key="country.id"
							    					v-bind:value="country.name">
							    					{{ country.name }}
							    				</option>
							    			</select>
							    		</div>
							    	</div>

							    	<div class="col-6">
							    		<div class="input-group-meta mb-25">
							    			<label>الولاية</label>
							    			<input type="text" name="state" v-model="old_deliver_addresses.state" placeholder="أدخل الولاية">
							    		</div>
							    	</div>
							    	<div class="col-6">
							    		<div class="input-group-meta mb-25">
							    			<label>المدينة</label>
							    			<input type="text" name="city" v-model="old_deliver_addresses.city" required="required" placeholder="أدخل المدينة">
							    		</div>
							    	</div>
							    	<div class="col-6">
							    		<div class="input-group-meta mb-25">
							    			<label>المنطقة</label>
							    			<input type="text" name="region" v-model="old_deliver_addresses.region" placeholder="أدخل المنطقة">
							    		</div>
							    	</div>
							    	<div class="col-12">
							    		<div class="input-group-meta mb-25">
							    			<label>اسم العنوان</label>
							    			<input type="text" name="address_name" v-model="old_deliver_addresses.address_name" placeholder="أدخل اسم العنوان">
							    		</div>
							    	</div>
							    	<div class="col-12">
							    		<div class="input-group-meta mb-25">
							    			<label>العنوان</label>
							    			<input type="text" name="address" v-model="old_deliver_addresses.address" required="required " placeholder="أدخل العنوان">
							    		</div>
							    	</div>
							    	<div class="col-6">
							    		<div class="input-group-meta mb-25">
							    			<label>رقم الهاتف</label>
							    			<input type="text" name="telephone" v-model="old_deliver_addresses.telephone" placeholder="أدخل الهاتف">
							    		</div>
							    	</div>
							    	<div class="col-6">
							    		<div class="input-group-meta mb-25">
							    			<label>الجوال</label>
							    			<input type="text" name="mobile" v-model="old_deliver_addresses.mobile" placeholder="أدخل الجوال">
							    		</div>
							    	</div>
							    	<div class="col-6">
							    		<div class="input-group-meta mb-25">
							    			<label>اسم الشركة</label>
							    			<input type="text" name="company_name" v-model="old_deliver_addresses.company_name" placeholder="أدخل اسم الشركة">
							    		</div>
							    	</div>
							    	<div class="col-6">
							    		<div class="input-group-meta mb-25">
							    			<label>الرمز البريدي</label>
							    			<input type="text" name="postcode" v-model="old_deliver_addresses.postcode" placeholder="أدخل الرمز البريدي">
							    		</div>
							    	</div>
							    	<div class="col-6">
							    		<div class="input-group-meta mb-25">
							    			<label>الدائرة الضريبية</label>
							    			<input type="text" name="tax_department" v-model="old_deliver_addresses.tax_department" placeholder="أدخل الدائرة الضريبية">
							    		</div>
							    	</div>
							    	<div class="col-6">
							    		<div class="input-group-meta mb-25">
							    			<label>الرقم الضريبي</label>
							    			<input type="text" name="tax_number" v-model="old_deliver_addresses.tax_number" placeholder="أدخل الرقم الضريبي">
							    		</div>
							    	</div> 
							    </div>

								<button class="btn-eight w-100 mt-50 mb-40 lg-mt-30 lg-mb-30" id="edit_address_deliver_button" type="submit"> تعديل العنوان </button>
							</form>
						</div>


					</div> <!-- /.form-wrapper -->
				</div>
			</div>
		</div>
	<!-- </div> /.item -->
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import PopUp from './PopUp.vue';
export default {

	name: 'DashBoard',

	data() {
		return {
			products: '',
			checked_products: [],
			products_weight: 0,
			products_volume: 0,
			products_price: 0,
			deliver_addresses: [],
			user_id: '',
			shipping_box_id: '',
			show: 1,
			countries: [],
			deliver_address: '',
			showDeliverAddress: 1,
			shipping_boxes: [],
			shipping_box: '',
			shipped_shipping_boxes: [],
			deposites: [],
			currency: '',
			shipping_box_ammount: 0,
			balance: '',
			payments: '',
			deliver_address_id: '',
			deliver_address_id_to_update: '',
			old_deliver_addresses: [],
            if_shipping_box_under_shipment : '',
		};
	},
	components: {
		PopUp

	},


	methods: {

		get_user_id() {
			axios.get('user',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					this.user_id = response.data.id;

					this.get_payments(this.user_id);
		            this.get_deposites(this.user_id);
				})
				.catch((error) => {
					console.log(error)
				})

		},


		get_total() {
			var checked_products = [];
			var total_weight = 0;
			var total_volume = 0;
			var total_price = 0;
			
			var product_name, product_category_id, product_id, product_weight, product_volume, product_price, product_currency_id = '';
			$('#mytable tbody tr').each(function () {
				if ($(this).find('input[type="checkbox"]').prop('checked')) {
					if( parseFloat($(this).find("td").eq(3).html()))
					
					total_weight +=parseFloat($(this).find("td").eq(3).html());
					if( parseFloat($(this).find("td").eq(4).html()))
					total_volume += parseFloat($(this).find("td").eq(4).html());
					total_price += parseFloat($(this).find("td").eq(5).html());

					product_name = $(this).find("td").eq(1).html();
					product_category_id = $(this).find("td").eq(2).html();
					product_weight = $(this).find("td").eq(3).html();
					product_volume = $(this).find("td").eq(4).html();
					product_price = $(this).find("td").eq(5).html();
					product_currency_id = $(this).find("td").eq(6).html();
					product_id = $(this).find("#product_id").val();
					console.log(product_id);
					checked_products.push({
						id: product_id,
						name: product_name,
						category_id: product_category_id,
						weight: product_weight,
						volume: product_volume,
						price: product_price,
						currency_id: product_currency_id

					});
				}


			});
			this.products_weight = total_weight;
			this.products_volume = total_volume;
			this.products_price = total_price;
			this.checked_products = checked_products;
		},

		get_products() {
			this.show = 1;
			axios.get('cabinet_products',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					this.products = response.data.data

				})
				.catch((error) => {
					console.log(error)
				})
		},



		change_products() {

			this.products = [];
			axios.get('cabinet_products',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					this.products = response.data.data
					this.get_total();

				})
				.catch((error) => {
					console.log(error)
				})
		},


		get_deliver_addresses() {
			axios.get('deliver_addresses',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					console.log(response);
					this.deliver_addresses = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
		},

		add_deliver_address() {
			this.showDeliverAddress = 1;
		},

		edit_deliver_address() {
			this.showDeliverAddress = 2;
		},

		edit_deliver_address_2() {
			this.showDeliverAddress = 3;
		},

		get_deliver_address_by_id(id) {
			this.showDeliverAddress = 0;

			


			if(document.getElementById("add_address_response") != null)
			{
				$('#add_address_response').removeClass("alert alert-success");
			    document.getElementById("add_address_response").innerHTML = " ";
			}


			if(document.getElementById("delete_address_response") != null)
			{
				$('#delete_address_response').removeClass("alert alert-danger");
			    document.getElementById("delete_address_response").innerHTML = " ";
				document.getElementById("delete_address_deliver_button").disabled = false;
			}

			axios.get("deliver_addresses/" + id,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					this.deliver_address = response.data.data;
					this.deliver_address_id_to_update = this.deliver_address.id ; 
					this.old_deliver_addresses = this.deliver_address ;
				})
				.catch((error) => {
					console.log(error)
				})

		},

		delete_address_deliver(id) {
		
		axios.delete("delete_deliver_addresses/" + id, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token')
			}
		})

			.then((response) => {
				$('#delete_address_response').addClass("alert alert-danger");

				if(response.data.message == "resource has been deleted successfully")
				{
					document.getElementById("delete_address_response").innerHTML = "تم حذف العنوان بنجاح";
					document.getElementById("delete_address_response").style.color = "black";
					// document.getElementById("delete_address_deliver_button").style.background-color = "#808080";
				    document.getElementById("delete_address_deliver_button").disabled = true;
					
				    this.get_deliver_addresses(); 

					this.deliver_address = '' ;
				}
				else if (response.data.message == "You cannot delete the delivery address because it is associated with a shipping box")
				{
					document.getElementById("delete_address_response").innerHTML = "لا يمكن حذف العنوان لأنه مرتبط بصندوق شحن ";
					document.getElementById("delete_address_response").style.color = "black";
				}
				else
				{
					document.getElementById("delete_address_response").innerHTML = response.data.message;
					document.getElementById("delete_address_response").style.color = "black";

				    // document.getElementById("delete_address_deliver_button").disabled = true;  
					
				}

				console.log(response);
				
			})

			.catch((error) => {
				console.log(error);

				$('#delete_address_response').addClass("alert alert-danger");

				document.getElementById("delete_address_response").innerHTML = error.message;
				document.getElementById("delete_address_response").style.color = "black";
				// document.getElementById("delete_address_deliver_button").disabled = true; 
			})
	    },

		mouseOver() {
			document.getElementById('hide-item-menu').style.display = 'block';
		},
		mouseOut() {
			document.getElementById('hide-item-menu').style.display = 'none';
		},
		remove_inner_html_shippingbox() {
			document.getElementById("shipping_box_response").innerHTML = '';
		},

		add_products_to_shipping_box() {
			const products = this.checked_products;
			var boxid = this.shipping_box_id;
			for (var i = 0; i < products.length; i++) {
				var productid = products[i].id;
				const form_data = new FormData();
				form_data.append('product_id', productid);
				form_data.append('box_id', boxid);
				const inputs = Object.fromEntries(form_data.entries());
				axios.post('add_product_to_shipping_box', inputs, {
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})

					.then((response) => {
						$('#shipping_box_response').addClass("alert alert-success");
						document.getElementById("shipping_box_response").innerHTML = response.data.message;
						document.getElementById("shipping_box_response").innerHTML = "تمت إضافة الطرود إلى صندوق الشحن بنجاح";
						document.getElementById("shipping_box_response").style.color = "green";
						document.getElementById("add_to_shippingbox").disabled = true;
						this.change_products();
					})
					.catch((error) => {
						console.log(error)
					})
			}
		},

		submit_shippingbox() {
			const form = new FormData(document.getElementById('submit_shippingbox'));
			const inputs = Object.fromEntries(form.entries());
			axios.post('shipping_boxes', inputs, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			})
				.then((response) => {
					this.shipping_box_id = response.data.data.id;
					this.add_products_to_shipping_box(); // method call to method2 
				})

				.catch((error) => {
					console.log(error)
				})


		},


		submit_payment() {
			const form = new FormData(document.getElementById('submit_payment'));
			const inputs = Object.fromEntries(form.entries());
			axios.post('payments', inputs, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			})

				.then((response) => {

					if(response.data.message == "Payment has been installed successfully")
					{
						$('#payment_response').addClass("alert alert-success");
					    // document.getElementById("payment_response").innerHTML = response.data.message;
					    document.getElementById("payment_response").innerHTML = "تم تثبيت الدفع بنجاح";
					    document.getElementById("payment_response").style.color = "green";
					    document.getElementById("payment_button").disabled = true;
					    console.log(response);
				     	this.get_shipping_box_by_id(response.data.data.shipping_box.id);    
					}
					else if(response.data.message == "Process failed, you don't have this balance...")
					{
						$('#payment_response').addClass("alert alert-danger");
					    document.getElementById("payment_response").innerHTML = "لا يوجد رصيد كافي";
					    document.getElementById("payment_response").style.color = "green";
					    // document.getElementById("payment_button").disabled = true;
					    console.log(response);
					}
					else
					{
						$('#payment_response').addClass("alert alert-success");
					    document.getElementById("payment_response").innerHTML = response.data.message;
					    // document.getElementById("payment_response").innerHTML = "تم تثبيت الدفع بنجاح";
					    document.getElementById("payment_response").style.color = "green";
					    document.getElementById("payment_button").disabled = true;
					    console.log(response);
				     	this.get_shipping_box_by_id(response.data.data.shipping_box.id); 
					}

					
				})

				.catch((error) => {
					console.log(error);
					if( error.response.data.message == "Process failed, you don't have this balance...")
					{
						$('#payment_response').addClass("alert alert-danger");
					    document.getElementById("payment_response").innerHTML = "لا يوجد رصيد كافي";
					    document.getElementById("payment_response").style.color = "green";
					    // document.getElementById("payment_button").disabled = true;
					    console.log('payment_response : ', error.response);
					}
					else
					{
						$('#payment_response').addClass("alert alert-danger");
					    document.getElementById("payment_response").innerHTML =  error.response.data.message ;
					    document.getElementById("payment_response").style.color = "green";
					    // document.getElementById("payment_button").disabled = true;
					    console.log('payment_response : ', error.response);
					}
					
					
				})
		},
		

		currentDate() {
			const current = new Date();
			const date = `${current.getFullYear()}-${current.getMonth()}-${current.getDate()}`;
			return date;
		},

		get_shipping_box_by_id(id) {
			axios.get("shipping_boxes/" + id,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					this.shipping_box = response.data.data;
					var products_price = this.shipping_box.price;
					var shipping_price = this.shipping_box.shipping_price;
					this.shipping_box_ammount = products_price + shipping_price;

					this.deliver_address_id = this.shipping_box.address[0].id ;
					console.log(this.deliver_address_id);

                    this.if_shipping_box_under_shipment = this.shipping_box.status ;
					console.log('this.if_shipping_box_under_shipment : ',this.if_shipping_box_under_shipment);
				})
				.catch((error) => {
					console.log(error)
				})
		},
		get_countries() {
			axios.get('countries')
				.then((response) => {
					this.countries = response.data.data
					console.log(this.countries)
				})
				.catch((error) => {
					console.log(error)
				})
		},

		get_deposites(user_id) {
			axios.get('getDepositesOf/'+user_id,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					this.deposites = response.data.data;
                    this.balance = response.data.data[0].user.profile.balance;
                    console.log('this.balance',response.data.data[0].user.profile.balance);
					//this.balance=response.data.data[0].user.profile.balance;
				})
				.catch((error) => {
					console.log(error)
				})
		},


		get_payments(user_id) {
			axios.get('getPaymentsOf/' + user_id,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					this.payments = response.data.data;
					console.log("paments" + response.data.data);
				})
				.catch((error) => {
					console.log(error)
				})
		},

		show_shipped_shipping_boxes() {
			this.shipping_box = '';
			this.show = 3;
			axios.get('get_shipped_shipping_boxes',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					console.log(response);
					this.shipped_shipping_boxes = response.data.data;
					this.shipping_box_ammount = '' ;
				})
				.catch((error) => {
					console.log(error)
				})
		},
		show_shipping_boxes() {
			this.shipping_box = '';
			this.show = 2;
			axios.get('shipping_boxes',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					this.shipping_boxes = response.data.data;
					this.shipping_box_ammount = '' ;
				})
				.catch((error) => {
					console.log(error)
				})
		},

		show_transactions() {
			this.show = 6;
			this.get_deposites(this.user_id);
			this.get_payments(this.user_id);
		},
		show_my_address_in_turkey() {
			this.show = 4;
		},
		show_deliver_addresses() {
			this.show = 5;

		},

		submit_deliver_address() {
			document.getElementById('add_new_address_deliver_button').disabled = true;
			const form = new FormData(document.getElementById('submit_deliver_address'));
			const inputs = Object.fromEntries(form.entries());
			axios.post('deliver_addresses' , inputs, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			})
				.then((response) => {

					$('#add_address_response').addClass("alert alert-success");
					

				if(response.data.message == "resource has been added successfully")
				{
					document.getElementById("add_address_response").innerHTML = "تمت إضافة العنوان بنجاح";
					document.getElementById("add_address_response").style.color = "black";
				}
				else
				{
					document.getElementById("add_address_response").innerHTML = response.data.message;
					document.getElementById("add_address_response").style.color = "black";
				}

				this.get_deliver_addresses();

				})
				.catch((error) => {
					console.log(error)
				})
		},


		submit_update_deliver_address() {
			document.getElementById('edit_address_deliver_button').disabled = true;
			const form = new FormData(document.getElementById('submit_update_deliver_address'));
			const inputs = Object.fromEntries(form.entries());
			axios.put('deliver_addresses/' + this.deliver_address_id_to_update , inputs, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			})
				.then((response) => {

				if(response.data.message == "Delivery Address updated successfully")
				{
					$('#edit_address_response2').addClass("alert alert-success");
					document.getElementById("edit_address_response2").innerHTML = "تم تعديل العنوان بنجاح";
					document.getElementById("edit_address_response2").style.color = "black";
				}
				else if(response.data.message == "You cannot update the delivery address because it is associated with a shipping box")
				{
					$('#edit_address_response2').addClass("alert alert-danger");
					document.getElementById("edit_address_response2").innerHTML = "لا يمكنك تعديل العنوان لأنه مرتبط بصندوق شحن قيد الشحن أو صندوق شحن مشحون";
					document.getElementById("edit_address_response2").style.color = "black";
				}
				else
				{
					$('#edit_address_response2').addClass("alert alert-success");
					document.getElementById("edit_address_response2").innerHTML = response.data.message;
					document.getElementById("edit_address_response2").style.color = "black";
				}

				})
				.catch((error) => {
					console.log(error)
				})
		},


		submit_update_shipping_boxes_deliver_address() {
			document.getElementById('edit_shipping_boxes_address_deliver_button').disabled = true;
			const form = new FormData(document.getElementById('submit_update_shipping_boxes_deliver_address'));
			const inputs = Object.fromEntries(form.entries());
			axios.put('/update_shipping_boxes/deliver_addresses/' + this.shipping_box.id , inputs, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			})
				.then((response) => {

					$('#edit_shipping_boxes_address_response').addClass("alert alert-success");

				// 	if(response.data.message == "resource has been added successfully")
				// {
					document.getElementById("edit_shipping_boxes_address_response").innerHTML = "تم تعديل العنوان بنجاح";
					document.getElementById("edit_shipping_boxes_address_response").style.color = "black";
				    // location.reload();
				// }
				// else
				// {
				// 	document.getElementById("edit_address_response").innerHTML = response.data.message;
				// 	document.getElementById("edit_address_response").style.color = "black";
				// }

				this.get_shipping_box_by_id(this.shipping_box.id);

				})
				.catch((error) => {
					console.log(error)
				})
		},

	},

	setup() {
		const popupTriggers = ref({
			buttonTrigger: false,
		});

		const TogglePopup = (trigger) => {
			popupTriggers.value[trigger] = !popupTriggers.value
			[trigger]
		}



		return {
			PopUp,
			popupTriggers,
			TogglePopup,
			//	submit_shippingbox
		}
	},

	created() {
		this.get_products();
		this.get_deliver_addresses();
		this.get_user_id();
		this.get_countries();
		// this.get_payments(this.user_id);
		// this.get_deposites(this.user_id);


	},


};

</script>