<template >
<div class="footer-style-four theme-basic-footer">
				<div class="container">
					<div class="inner-wrapper">
						<div class="row">
							<div class="col-lg-4 footer-intro mb-40">
								<!-- <div class="logo"><a href="index.html"><img src="/frontend/images/logo/Turk-cini.png" alt="" width="130"></a></div> -->
								<div class="logo"><router-link :to="{ name: 'HomePage' }" role="button"> <img src="/frontend/images/logo/Turk-cini.png" alt="" width="130"></router-link></div>

								<p>يحقق المارد التركي للأشخاص خارج تركيا فرصة تجربة الشراء من أهم الماركات التركية وأشهرها وتجميع مشترياتهم في مكان واحد لشحنها بتكلفة أقل وبطريقة مناسبة.</p>
								<ul class="d-flex social-icon style-none">
									<li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
									<li><a href="#"><i class="fab fa-twitter"></i></a></li>
									<li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
								</ul>
							</div>
							<div class="col-lg-2 col-sm-4 ms-auto mb-30">
								<h5 class="footer-title">الوصول السريع  </h5>
								<ul class="footer-nav-link style-none">
									<li> <router-link :to="{ name: 'Portfolio' }" role="button">المتاجر</router-link></li>
									<li> <router-link :to="{ name: 'Pricing' }" role="button">الاسعار</router-link></li>
									<li> <router-link :to="{ name: 'SignUp' }" role="button">الاشتراك</router-link></li>
									<li> <router-link :to="{ name: 'DashBoard' }" role="button">الحساب</router-link></li>
								</ul>
							</div>
							<div class="col-lg-3 col-sm-4 mb-30">
								<h5 class="footer-title">روابط مفيدة</h5>
								<ul class="footer-nav-link style-none">
									<li> <router-link :to="{ name: 'Faq' }" role="button">الاسئلة الشائعة</router-link></li>
									<li> <router-link :to="{ name: 'About' }" role="button">معلومات عنا</router-link></li>
									<li> <router-link :to="{ name: 'Bloge' }" role="button">المدونة</router-link></li>
									<li> <router-link :to="{ name: 'ContactUs' }" role="button">اتصل بنا</router-link></li>
								</ul>
							</div>
							<div class="col-xl-2 col-lg-3 col-sm-4 mb-30">
								<h5 class="footer-title"> قانونية </h5>
								<ul class="footer-nav-link style-none">
									<li> <router-link :to="{ name: 'HomePage' }" role="button">الشروط والاحكام</router-link></li>
									<li> <router-link :to="{ name: 'HomePage' }" role="button">سياسة الخصوصية</router-link></li>
									<li> <router-link :to="{ name: 'HomePage' }" role="button">اتفاقية البيع عن بعد</router-link></li>
									<li> <router-link :to="{ name: 'HomePage' }" role="button">سياسة الارتجاع</router-link></li>
								</ul>
							</div>
						</div>

						<div class="bottom-footer">
							<div class="d-lg-flex justify-content-between align-items-center">
								<ul class="order-lg-1 pb-15 d-flex justify-content-center footer-nav style-none">
									<li><a href="https://zanobia.me/">المطور ZANOBIA.ME </a></li>
								</ul>
								<p class="copyright text-center order-lg-0 pb-15"> جميع الحقوق محفوظة Turkey Genie 2022</p>
							</div>
						</div>
					</div> <!-- /.inner-wrapper -->
				</div>
			</div> <!-- /.footer-style-four -->
</template>

<script>
export default {

name: 'Footer',
        
    };

</script>