import { createRouter, createWebHashHistory } from 'vue-router'

import HomePage from '../views/pages/HomePage.vue'
import ServicesPage from '../views/pages/ServicesPage.vue'
import PortfolioPage from '../views/pages/Portfolio.vue'
import BlogePage from '../views/pages/Bloge.vue'
import PricingPage from '../views/pages/Pricing.vue'
import AboutPage from '../views/pages/About.vue'
import ContactUsPage from '../views/pages/ContactUs.vue'
import SignInPage from '../views/pages/SignIn.vue'
import SignUpPage from '../views/pages/SignUp.vue'
import DashBoardPage from '../views/pages/DashBoard.vue'
import BlogDetilePage from '../views/pages/BlogDetile.vue'
import AddProfilePage from '../views/pages/AddProfile.vue'
import ViewProfilePage from '../views/pages/ViewProfile.vue'
import FaqBage from '../views/pages/Faq.vue'
import LogOutPage from '../views/pages/LogOut.vue'
import AddOrderPage from '../views/pages/AddOrder.vue'






const newLocal = 'Pricing'
const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/Services',
    name: 'Services',
    component: ServicesPage
  },
  {
    path: '/Portfolio',
    name: 'Portfolio',
    component: PortfolioPage
  },
  {
    path: '/Bloge',
    name: 'Bloge',
    component: BlogePage
  },
  {
    path: '/Pricing',
    name: 'Pricing',
    component: PricingPage
  },
  {
    path: '/About',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUsPage
  },
  {
    path: '/SignIn',
    name: 'SignIn',
    component: SignInPage
  },
  {
    path: '/SignUp',
    name: 'SignUp',
    component: SignUpPage
  },
  {
    path: '/DashBoard',
    name: 'DashBoard',
    component: DashBoardPage
  },
  {

    path: '/BlogDetile/:id',
    name: 'BlogDetile',
    component: BlogDetilePage
  },

  {
    path: '/AddProfile',
    name: 'AddProfile',
    component: AddProfilePage
  },
  {
    path: '/ViewProfile',
    name: 'ViewProfile',
    component: ViewProfilePage
  },
  {
    path: '/Faq',
    name: 'Faq',
    component: FaqBage
  },
  {
    path: '/LogOut',
    name: 'LogOut',
    component: LogOutPage
  },

  {
    path: '/AddOrder',
    name: 'AddOrder',
    component: AddOrderPage
  },



]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
