<template >
<br> <br> <br> 
	<div class="user-data-page clearfix d-md-flex">
				<div class="illustration-wrapper d-none d-md-flex align-items-center">
					<h3>معلومات الحساب<br>  <a href="sign-up.html"></a></h3>
					<div class="illustration-holder">
						<img src="images/assets/ils_22.svg" alt="" class="illustration m-auto">
					</div>
				</div> <!-- /.illustration-wrapper -->

				<div class="form-wrapper">
					<div class="d-flex justify-content-between align-items-center">
						<div class="logo"><a href="index.html" class="d-block"><img src="images/logo/logo_01.png" alt="" width="131"></a></div>
						<!-- <a href="index.html" class="go-back-button tran3s">الذهاب إلى الرئيسية</a> -->

            <a href="/#/" class="go-back-button tran3s">الذهاب إلى الرئيسية</a>

					</div>
				
						<h2>معلوماتي</h2>
                       
					  <table  class="table table-hover table-responsive-sm" >
                        <tbody>

                         <tr>
                          <th>اسم المستخدم:</th>
                          <td>{{user_name}}</td>
                        </tr>

                        <tr>
                          
                          <th>الاسم الأول:</th>
                          <td>{{profile.firstname}}</td>
                        </tr>
                        <tr>
                          <th>الاسم الأخير:</th>
                          <td>{{profile.lastname}}</td>
                         </tr>
                         <tr>
                             <th>الدولة:</th>
                             <td>{{profile.country_name}}</td>
                         </tr>
                         <tr>
                            <th>الولاية :</th>
                            <td>{{profile.state}}</td>
                         </tr> 
                         <tr>
                             <th>المدينة:</th>
                             <td>{{profile.city}}</td>
                         </tr>
                         <tr>
                             <th>المنطقة :</th>
                             <td>{{profile.region}}</td>
                         </tr>   
                         <tr>
                             <th>اسم العنوان :</th>
                             <td>{{profile.address_name}}</td>
                         </tr>  
                         <tr>
                             <th>العنوان :</th>
                             <td>{{profile.address}}</td>
                         </tr>
                         <tr>
                             <th>رقم الهاتف:</th>
                             <td>{{profile.telephone}}</td>
                         </tr>
                          <tr>
                             <th>الجوال:</th>
                             <td>{{profile.mobile}}</td>
                         </tr>
                          <tr>
                             <th>اسم الشركة:</th>
                             <td>{{profile.company_name}}</td>
                         </tr>
                          <tr>
                             <th>الرمز البريدي:</th>
                             <td>{{profile.postcode}}</td>
                         </tr>
                         <tr>
                             <th>الدائرة الضريبية:</th>
                             <td>{{profile.tax_department}}</td>
                         </tr>
                          <tr>
                             <th> الرمز الضريبي:</th>
                             <td>{{profile.tax_number}}</td>
                         </tr>
                </tbody>
                       </table>
						</div>
				</div> <!-- /.form-wrapper -->
			
</template>

<script>
import { logicalExpression } from '@babel/types';
import axios from 'axios';
export default {

name: 'ViewProfile',
data() {
    return {
      profile: '',
      user_name:'',
    };
  },

  methods: {
     getData() {
      
      axios.get('profile',
      {
        headers:{
			  Authorization:'Bearer '+localStorage.getItem('token')
		}
     })
     .then((response)=>{
		this.profile=response.data.data
		console.log(this.profile)
	 })
	 .catch((error)=>{
		console.log(error)
	 })
    },



  get_user()
  {
  axios.get('user',
					{
						headers:{
							Authorization:'Bearer '+localStorage.getItem('token')
							}
					})
					.then((response)=>{
					this.user_name=response.data.name;
				
					})
					.catch((error)=>{
						console.log(error)
					})
  }

  },

  created() {
    this.getData();
    this.get_user();
  },
};
</script>