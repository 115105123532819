<template >

<Faq-page></Faq-page>

</template>

<script>
import  FaqPage from '../../components/Pages/Faq.vue';

export default {
   name: "Faq",
    components: { 
        FaqPage,
				    }, 
    };

</script>