<template >

<Bloge-page></Bloge-page>

</template>

<script>
import  BlogePage from '../../components/Pages/Bloge.vue';

export default {
   name: "Bloge",
    components: { 
        BlogePage,
				    }, 
    };

</script>