<template >

<View-Profile-page></View-Profile-page>

</template>

<script>
import  ViewProfilePage from '../../components/Pages/ViewProfile.vue';

export default {
   name: "Viewrofile",
    components: { 
        ViewProfilePage,
				    }, 
    };

</script>