
<template >
	<div class="theme-inner-banner">
		<div class="container">
			<h2 class="intro-title text-center">الأسعار</h2>
			<ul class="page-breadcrumb style-none d-flex justify-content-center">
				<li><a href="index.html">الرئيسية</a></li>
				<li class="current-page">الأسعار</li>
			</ul>
		</div>
		<img src="images/shape/shape_38.svg" alt="" class="shapes shape-one">
		<img src="images/shape/shape_39.svg" alt="" class="shapes shape-two">
	</div> <!-- /.theme-inner-banner -->




	<!--
			=====================================================
				Pricing Section One
			=====================================================
			-->
	<!-- <div class="pricing-section-one mt-130 lg-mt-110">
		<div class="container" data-aos="fade-up">
			<div class="row">
				<div class="col-xxl-7 col-xl-8 col-lg-7 col-md-9 m-auto">
					<div class="title-style-one text-center">
						<h2 class="main-title">Solo, Agency or Team? We’ve got you Covered</h2>
					</div> 
				</div>
			</div>

			<ul class="nav nav-tabs justify-content-center pricing-nav-one" role="tablist">
				<li class="nav-item" role="presentation">
					<button class="nav-link active" data-bs-toggle="tab" data-bs-target="#month" type="button"
						role="tab">Monthly</button>
				</li>
				<li class="nav-item" role="presentation">
					<button class="nav-link" data-bs-toggle="tab" data-bs-target="#year" type="button"
						role="tab">Yearly</button>
				</li>
			</ul>
		</div> 

		<div class="pricing-table-area-one" data-aos="fade-up" data-aos-delay="100">
			<div class="container">
				<div class="tab-content">
					<div class="tab-pane active show" id="month">
						<div class="row gx-xxl-5">
							<div class="col-md-6">
								<div class="pr-table-wrapper active md-mb-30">
									<div class="pack-name">Business</div>
									<div class="pack-details">For individuals and teams. Get <span>1 year <br> premium
											market access</span></div>
									<div class="top-banner d-sm-flex justify-content-center align-items-center">
										<div class="price"><sup>$</sup>75</div>
										<div>
											<span>Monthly membership</span>
											<em>Starting at $75/mo with </em>
										</div>
									</div>
									<ul class="pr-feature style-none">
										<li>Unlimited campaigns</li>
										<li>Push Notifications</li>
										<li>Team fundraising</li>
									</ul>
									<a href="#" class="trial-button">Try us without risk. <span>Choose plan <i
												class="fas fa-chevron-right"></i></span> </a>
								</div> 
							</div>
							<div class="col-md-6">
								<div class="pr-table-wrapper">
									<div class="pack-name">Agency</div>
									<div class="pack-details">For big agency & teams. Get <span>1 year <br> premium market
											access</span></div>
									<div class="top-banner d-sm-flex justify-content-center align-items-center">
										<div class="price"><sup>$</sup>99</div>
										<div>
											<span>Monthly membership</span>
											<em>Starting at $99/mo with </em>
										</div>
									</div> 
									<ul class="pr-feature style-none">
										<li>Unlimited campaigns</li>
										<li>Push Notifications</li>
										<li>Team fundraising</li>
									</ul>
									<a href="#" class="trial-button">Try us without risk. <span>Choose plan <i
												class="fas fa-chevron-right"></i></span> </a>
								</div> 
							</div>
						</div>
					</div>

					<div class="tab-pane" id="year">
						<div class="row gx-xxl-5">
							<div class="col-md-6">
								<div class="pr-table-wrapper active md-mb-30">
									<div class="pack-name">Business</div>
									<div class="pack-details">For individuals and teams. Get <span>2 year <br> premium
											market access</span></div>
									<div class="top-banner d-sm-flex justify-content-center align-items-center">
										<div class="price"><sup>$</sup>69</div>
										<div>
											<span>Yearly membership</span>
											<em>Starting at $69/mo with </em>
										</div>
									</div> 
									<ul class="pr-feature style-none">
										<li>Unlimited campaigns</li>
										<li>Push Notifications</li>
										<li>Team fundraising</li>
									</ul>
									<a href="#" class="trial-button">Try us without risk. <span>Choose plan <i
												class="fas fa-chevron-right"></i></span> </a>
								</div> 
							</div>
							<div class="col-md-6">
								<div class="pr-table-wrapper">
									<div class="pack-name">Agency</div>
									<div class="pack-details">For big agency & teams. Get <span>2 year <br> premium market
											access</span></div>
									<div class="top-banner d-sm-flex justify-content-center align-items-center">
										<div class="price"><sup>$</sup>97</div>
										<div>
											<span>Yearly membership</span>
											<em>Starting at $97/mo with </em>
										</div>
									</div>
									<ul class="pr-feature style-none">
										<li>Unlimited campaigns</li>
										<li>Push Notifications</li>
										<li>Team fundraising</li>
									</ul>
									<a href="#" class="trial-button">Try us without risk. <span>Choose plan <i
												class="fas fa-chevron-right"></i></span> </a>
								</div> 
							</div>
						</div>
					</div>
				</div> 

				<div class="msg-note mt-80 lg-mt-50" data-aos="fade-up">If you Need any Custom or others Pricing System.
					<br> Please <a href="contact-us.html">Send Message</a></div>
			</div>
		</div> 
	</div>  -->
	<!-- /.pricing-section-one -->



	<!--
			=====================================================
				Pricing Section Two
			=====================================================
			-->
	<div class="pricing-section-two position-relative mt-150 mb-180 lg-mt-80 lg-mb-80">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-xl-7 col-lg-6 col-md-8 m-auto">
					<div class="title-style-one text-center mb-20" data-aos="fade-up">
						<div class="sc-title-five"> الخطط والأسعار </div>
						<h1 class="main-title">اختر خطتك</h1>
					</div> <!-- /.title-style-one -->
				</div>
			</div>
			<div class="pricing-table-area-two">
				<div class="row">
					<div class="col-xxl-10 m-auto">
						<div class="row justify-content-center" >
							<div class="col-lg-4 col-sm-6" data-aos="fade-right"  v-for="price in prices" :key="price.id">
								<div class="pr-table-wrapper tran3s mt-40 mx-2" style="border-style: double; border-color: #00A651;">
									<div class="pack-name"><h3 style="color: #00A651;"> {{ price.name }}</h3></div>
									<div class="price"><sup>$</sup>{{price.price}}</div>
									<h4 style="color: #00A651;">مميزات الخطة</h4>
									<ul class="pr-feature style-none">
										<li>{{price.description}}</li>
									</ul>
									<a href="#" class="btn-seven w-100 mt-50">Choose Plan</a>
								</div> <!-- /.pr-table-wrapper -->
							</div>

							
						</div>
					</div>
				</div>

				<!-- <div class="row">
					<div class="col-xxl-5 col-xl-6 col-lg-7 m-auto">
						<p class="info mt-75 lg-mt-50" data-aos="fade-up">We've done it carefully and simply. Combined with
							the ingredients makes for beautiful landings.</p>
					</div>
				</div> -->
			</div> <!-- /.pricing-table-area-two -->
		</div>
		<img src="images/shape/shape_34.svg" alt="" class="shapes shape-one">
	</div> <!-- /.pricing-section-two -->
</template>

<script>
import axios from 'axios';

export default {

	name: 'Pricing',


	
data() {
		return {
			prices: [],
		}
	},

	mounted() {
		this.get_prices();
	},


	methods: {

		get_prices() {

            axios.get("prices")
	        .then((response) => {
				console.log(response);
		        this.prices = response.data.data;
	        })
	        .catch((error) => {
	           console.log(error)
	        })
       },


	}



};

</script>