<template>
<section>
    <div id="preloader">
        <div id="ctn-preloader" class="ctn-preloader">
            <div class="animation-preloader">
                <div class="icon"><img src="/frontend/images/logo/Turk-cini.png" alt="" class="m-auto d-block" width="200"></div>
                <div class="txt-loading mt-2">

                    <span data-text-preloader="E" class="letters-loading">
                        E
                    </span>

                    <span data-text-preloader="I" class="letters-loading">
                        I
                    </span>
                    <span data-text-preloader="N" class="letters-loading">
                        N
                    </span>
                    <span data-text-preloader="E" class="letters-loading">
                        E
                    </span>
                    <span data-text-preloader="C" class="letters-loading">
                        C
                    </span>
                    <span data-text-preloader="" class="letters-loading">

                    </span>
                    <span data-text-preloader="Y" class="letters-loading">
                        Y
                    </span>
                    <span data-text-preloader="E" class="letters-loading">
                        E
                    </span>
                    <span data-text-preloader="K" class="letters-loading">
                        K
                    </span>
                    <span data-text-preloader="R" class="letters-loading">
                        R
                    </span>
                    <span data-text-preloader="U" class="letters-loading">
                        U
                    </span>
                    <span data-text-preloader="T" class="letters-loading">
                        T
                    </span>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- 

=============================================

Search

============================================== 

-->
<div class="offcanvas offcanvas-top theme-search-form bg-three justify-content-center" tabindex="-1" id="offcanvasTop">
    <button type="button" class="close-btn tran3s" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg"></i></button>
    <div class="form-wrapper">
        <form action="#">
            <input type="text" placeholder="Search Keyword....">
        </form>
    </div> <!-- /.form-wrapper -->
</div>

<!-- 

=============================================

Theme Main Menu

============================================== 

-->
<header class="theme-main-menu sticky-menu theme-menu-four">
    <div class="inner-content">
        <div class="d-flex align-items-center">
            <div class="logo order-lg-0"><a href="index.html" class="d-block"><img src="/frontend/images/logo/Turk-cini.png" alt="" width="140"></a></div>

            <div v-if="isAuth === false" class="right-widget d-flex align-items-center ms-auto order-lg-3">
                <router-link :to="{ name: 'SignIn' }" class="send-msg-btn tran3s d-none d-lg-block"> {{logIn}}</router-link>
            </div> <!-- /.right-widget -->

            <div v-else-if="(isAuth === true) && ((isAdmin==1)||(isAdmin==2)) " class="right-widget d-flex align-items-center ms-auto order-lg-3">
                <a href='https://turkey-cenie.com/index.php/' class="send-msg-btn tran3s d-none d-lg-block"> {{adminPanel}}</a>
            </div> <!-- /.right-widget -->

            <div v-else-if="isAuth === true && (isAdmin==3)" class="right-widget d-flex align-items-center ms-auto order-lg-3">
                <router-link :to="{ name: 'DashBoard' }" class="send-msg-btn tran3s d-none d-lg-block"> {{dashoard}}</router-link>
            </div> <!-- /.right-widget -->

            <div v-if="isAuth === false" class="right-widget d-flex align-items-center ms-auto order-lg-3">
                <router-link :to="{ name: 'SignUp' }" class="send-msg-btn tran3s d-none d-lg-block regist"> {{register}}</router-link>
            </div> <!-- /.right-widget -->
            <div v-if="isAuth === true" class="right-widget d-flex align-items-center ms-auto order-lg-3">
                <router-link :to="{ name: 'LogOut' }" class="send-msg-btn tran3s d-none d-lg-block regist"> {{logout}}</router-link>
            </div> <!-- /.right-widget -->

            <nav class="navbar navbar-expand-lg order-lg-2">
                <button class="navbar-toggler d-block d-lg-none " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" style="z-index: 1000000; float:right !important;">
                    <span></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="d-block d-lg-none">
                            <div class="logo"><a href="index.html"><img src="/frontend/images/logo/Turk-cini.png" alt="" width="130"></a></div>
                        </li>

                        <li class="nav-item nav-item-one">
                            <!-- <router-link class="nav-link" :to="{ name: 'HomePage' }" role="button">الرئيسية</router-link> -->
                            <button class="nav-link" @click="Redirect_Reload_Home_page">الرئيسية</button>
                        </li>
                        <li class="nav-item nav-item-one">
                            <router-link class="nav-link" :to="{ name: 'Portfolio' }" role="button">المتاجر</router-link>
                            <!-- <button class="nav-link" @click="Redirect_Reload_Portfolio_page">المتاجر</button> -->

                        </li>
                        <li class="nav-item nav-item-one">
                            <!-- <router-link class="nav-link" :to="{ name: 'Pricing' }"  role="button">الاسعار</router-link> -->
                            <button class="nav-link" @click="Redirect_Reload_Pricing_page">الاسعار</button>
                        </li>

                        <li class="nav-item nav-item-one">
                            <!-- <router-link class="nav-link" :to="{ name: 'About' }" role="button">معلومات عنا</router-link>  -->
                            <button class="nav-link" @click="Redirect_Reload_About_page">معلومات عنا</button>
                        </li>

                        <li class="nav-item nav-item-one">
                            <router-link class="nav-link" :to="{ name: 'ContactUs' }" role="button">اتصل بنا</router-link>
                            <!-- <button class="nav-link" @click="Redirect_Reload_Contact_page">اتصل بنا</button> -->
                        </li>

                    </ul>

                    <div class="dropdown">
                        <button class="dropbtn menu-search-btn  nav-link"> <i class="bi bi-bell"></i></button>
                        <div class="dropdown-content">
                            <a href="#"  v-for="notification in notifications" :key="notification.id">{{notification.data.name}}</a>
                        </div>
                    </div>

                    <router-link class="nav-link" :to="{ name: 'ViewProfile' }" role="button">
                        <button class="menu-search-btn tran3s" type="button"><i class="bi bi-person"></i>
                        </button>
                    </router-link>
                    <!-- <button @click="Redirect_Reload_View_Profile_page" class="menu-search-btn tran3s" type="button" ><i class="bi bi-person"></i>

</button> -->

                    <!-- Mobile Content 

    	<div class="mobile-content d-block d-lg-none">

<div class="d-flex flex-column align-items-center justify-content-center mt-70">

<ul>

<li class="nav-item nav-item-one registerr">

<router-link class="nav-link" :to="{ name: 'SignIn' }" role="button">{{ callaction }}</router-link> 

           </li>

   <li class="nav-item nav-item-one loginn">

<router-link class="nav-link" :to="{ name: 'SignIn' }" role="button">{{  register }}</router-link> 

           </li>

</ul>

</div>

    	</div>  /.mobile-content -->
                </div>
            </nav>
        </div>
    </div> <!-- /.inner-content -->
</header> <!-- /.theme-main-menu -->
</template>

<script>
import axios from 'axios';
import {
    useRouter
} from 'vue-router';

export default {

    name: 'Header',

    data() {
        return {
            logIn: '',
            register: '',
            dashoard: '',
            logout: '',
            adminPanel: '',
            isAuth: false,
            isAdmin: false,
            notifications: []
        }
    },

    computed: {
        get_notifications() {
            axios.get('user_notifications', {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.notifications = response.data.data;

                })
                .catch((error) => {
                    console.log(error)
                })
        },

        isLoggedIn() {
            axios.get('user', {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                        Accept: 'application/json'
                    }
                })
                .then((response) => {

                    this.isAuth = true;
                    this.dashoard = 'شحناتي',
                        this.logout = 'تسجيل الخروج',
                        this.isAdmin = response.data.role,
                        this.adminPanel = 'لوحة التحكم'

                })
                .catch((error) => {
                    this.logIn = 'تسجيل الدخول',
                        this.register = 'الاشتراك ',
                        this.isAuth = false;
                })

        }
    },

    created() {
        this.isLoggedIn
		this.get_notifications
    },

    methods: {

        Redirect_Reload_Home_page() {
            this.$router
                .push({
                    path: '/'
                })
                .then(() => {
                    this.$router.go()
                })
        },

        //   Redirect_Reload_Portfolio_page() {
        //     this.$router
        //       .push({ path: '/Portfolio' })
        //       .then(() => { this.$router.go() })
        //   },

        Redirect_Reload_Pricing_page() {
            this.$router
                .push({
                    path: '/Pricing'
                })
                .then(() => {
                    this.$router.go()
                })
        },

        Redirect_Reload_About_page() {
            this.$router
                .push({
                    path: '/About'
                })
                .then(() => {
                    this.$router.go()
                })
        },

        Redirect_Reload_View_Profile_page() {
            this.$router
                .push({
                    path: '/ViewProfile'
                })
                .then(() => {
                    this.$router.go()
                })
        },

        //   Redirect_Reload_Contact_page() {
        //     this.$router
        //       .push({ path: '/ContactUs' })
        //       .then(() => { this.$router.go() })
        //   },
    }

};
</script>
