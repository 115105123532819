<template>
    <div class="popup">
        <div class="popup-inner">
            <slot ></slot>
            <button class="btn btn-light" @click="TogglePopup()">إغلاق</button>
        </div>
    </div>
</template>


<style >
.popup{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:99;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup-inner{
    background: #FFF;
    padding: 5%;
    width:60%;
    height:auto;
    border-radius: 16px;
    margin-top:2%;
}
.btn-light{
float:left
}
</style>


<script>

export default({
  name: 'PopUp', 
  props:['TogglePopup']
})

</script>
