<template>
    <!-- ===================================================
				Loading Transition
			==================================================== -->
          <header-section></header-section>
			<!-- 
			=============================================
				Theme Hero Banner
			============================================== 
			-->

			<router-view></router-view>
            <!--
			=====================================================
				News Letter Section
			=====================================================
			-->
			<news-letter-section> </news-letter-section>
			<!--
			=====================================================
				Footer Section
			=====================================================
			-->
            <footer-section></footer-section>
			<!--  ==========================================   -->
			<button class="scroll-top">
				<i class="bi bi-arrow-up-short"></i>
			</button>

</template>

<script>
import HeaderSection from '../../components/HomePage/Header.vue';
import NewsLetterSection from '../../components/HomePage/NewsLetter.vue';
import FooterSection from '../../components/HomePage/Footer.vue';


export default {
   name: "Master",
    components: { HeaderSection,
				   NewsLetterSection,
				   FooterSection,
				    }, 
    };

</script>
