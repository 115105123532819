<template >
<div class="partner-section-two mt-30 mb-130 lg-mb-80">
				<div class="container">
					<div class="row">
						<div class="col-12 m-auto">
							<ul class="style-none text-center mt-40 lg-mt-10">
								<li class="partner-logo-block-one d-inline-block" data-aos="fade-up"><a href="#" class="d-flex align-items-center justify-content-center"><img src="/frontend/images/assets/logo1.webp" alt=""></a></li>
								<li class="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay="100"><a href="#" class="d-flex align-items-center justify-content-center"><img src="/frontend/images/assets/logo2.webp" alt=""></a></li>
								<li class="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay="200"><a href="#" class="d-flex align-items-center justify-content-center"><img src="/frontend/images/assets/logo3.webp" alt=""></a></li>
								<li class="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay="300"><a href="#" class="d-flex align-items-center justify-content-center"><img src="/frontend/images/assets/logo4.webp" alt=""></a></li>
							</ul>
						</div>
					</div>
				</div> <!-- /.container -->
			</div> <!-- /.partner-section-two -->
</template>

<script>
export default {

name: 'Partener',
        
    };

</script>