<template >
			<div class="blog-details pt-90 mb-150 lg-pt-40 lg-mb-100">
				<div class="container">
					<div class="row">
						<div class="col-xxl-11 m-auto">
							<div class="row">
								<div class="col-lg-12" >
									<article class="blog-meta-three tran3s mt-45">
										<figure class="post-img m0"><img src="images/blog/blog_img_20.jpg" alt="" class="w-100 tran4s"></figure>
										<div class="post-data">
											<div class="post-tag"></div>
											<div class="blog-title"><h4>{{ blog.title }}</h4></div>
											<p>{{ blog.content }}</p>

											<div class="bottom-widget d-sm-flex align-items-center justify-content-between">
												<ul class="d-flex share-icon align-items-center style-none pt-10">
													<li>مشاركة:</li>
													<li><a href="#"><i class="bi bi-google"></i></a></li>
													<li><a href="#"><i class="bi bi-twitter"></i></a></li>
													<li><a href="#"><i class="bi bi-instagram"></i></a></li>
												</ul>
											</div>
										</div> <!-- /.post-data -->
									</article>
									
								</div>

							</div>
						</div>
					</div>
				</div> <!-- /.container -->
			</div> <!-- /.blog-details -->
</template>


<script>
  import { useRouter,useRoute } from 'vue-router'
      import axios from 'axios';
export default {

name: 'BlogDetile',
       

data() {
    return {
    id:'',
    blog:[],

    }
}, 



methods:{

	get_blog_details(){
       
       axios.get("blog/"+this.id,
       {
       headers:{
       Authorization:'Bearer '+localStorage.getItem('token')
       }
       })
       .then((response)=>{
       this.blog=response.data.data;
       })
       .catch((error)=>{
       console.log(error)
       })
},

},

mounted(){
    const route = useRoute();
    this.id = route.params.id;
    this.get_blog_details();
   }

    };

</script>