<template >
<br> <br> <br> 
	<div class="user-data-page clearfix d-md-flex">
				<div class="illustration-wrapper d-none d-md-flex align-items-center">
										<h3>احصل على جميع رغباتك وأنت في مكانك</h3>
					<div class="illustration-holder">
						<img src="images/assets/ils_22.svg" alt="" class="illustration m-auto">
					</div>
				</div> <!-- /.illustration-wrapper -->

				<div class="form-wrapper">
					<div class="d-flex justify-content-between align-items-center">
						<div class="logo"><a href="index.html" class="d-block"><img src="images/logo/logo_01.png" alt="" width="131"></a></div>
					</div>
					<form action="#" @submit.prevent="submit" class="user-data-form mt-60 lg-mt-40">
						<h2>مرحبا   بعودتك !</h2>
						<p class="header-info pt-20 pb-20 lg-pt-10 lg-pb-10">ليس لديك حساب ?  <router-link :to="{ name: 'SignUp' }" role="button">الاشتراك </router-link> </p>


						
						<div class="row">
							<div class="col-12">
								<div id="response"></div>
								<div class="input-group-meta mb-25">
									<label>البريد الالكتروني</label>
									<input type="email" name="email" placeholder="example@mail.com">
								</div>
							</div>
							<div class="col-12">
								<div class="input-group-meta mb-25">
									<label>كلمة المرور</label>
									<input type="password" placeholder="الرجاء ادخال كلمة المرور" name="password" class="pass_log_id">
									<span class="placeholder_icon"><span class="passVicon"><img src="images/icon/icon_40.svg" alt=""></span></span>
								</div>
							</div>
							<div class="col-12">
								<div class="agreement-checkbox d-flex justify-content-between align-items-center">
									<!-- <div>
										<input type="checkbox" id="remember">
										<label for="remember">Keep me logged in</label>
									</div> -->
									<a href="#">هل فقدت كلمة المرور?</a>
								</div> <!-- /.agreement-checkbox -->
							</div>
							<div class="col-12">
								<button  class="btn-eight w-100 mt-50 mb-40 lg-mt-30 lg-mb-30" type="submit">تسجيل الدخول</button>
							</div>
						</div>
					</form>
				</div> <!-- /.form-wrapper -->
			</div> <!-- /.user-data-page -->

</template>

<script>
import { logicalExpression } from '@babel/types';
import axios from 'axios';
import { useRouter } from 'vue-router';
export default {

name: 'SignIn',
 
     setup(){
        const router=useRouter();
        const submit=async e =>{
		const form =new FormData(e.target);
		const inputs=Object.fromEntries(form.entries());
		
		const response=await axios.post('login',inputs)
		
	  .catch((error)=>{
		
	document.getElementById('response').innerHTML="البريد الإلكتروني أو كلمة المرور غير صالحين";
	document.getElementById('response').style.background="#edb2ad";
	 })	;	
		axios.defaults.headers.common['Authorization'] =  'Bearer ' + response.data.data.token;
		localStorage.setItem('token',response.data.data.token);
		console.log('Bearer ' + response.data.data.token);
	
	    axios.get('user',{
		headers:{
			Authorization:'Bearer '+localStorage.getItem('token'),
			Accept:'application/json'
		}
		 })
       .then((response)=>{
		if(response.data.role==3)
		 {
		  router.push('/');
		 }
		 else{
			window.location.href = "index.php"
		 }
	 })
	 .catch((error)=>{
		console.log(error)
		console.log(localStorage.getItem('token'))
	 })
		//await router.push('/');
	  }

	  return {
		submit
	  }
    },
  
   

};
</script>