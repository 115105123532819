<template >
<br> <br> <br> 
	<div class="user-data-page clearfix d-md-flex">
				<div class="illustration-wrapper d-none d-md-flex align-items-center">
										<h3>احصل على جميع رغباتك وأنت في مكانك</h3>
					<div class="illustration-holder">
						<img src="images/assets/ils_22.svg" alt="" class="illustration m-auto">
					</div>
				</div> <!-- /.illustration-wrapper -->

				<div class="form-wrapper">
					<div class="d-flex justify-content-between align-items-center">
						<div class="logo"><a href="index.html" class="d-block"><img src="images/logo/logo_01.png" alt="" width="131"></a></div>
					</div>
					<form action="#" @submit.prevent="submit" class="user-data-form mt-60 lg-mt-40">
						<h2>تسجيل المعلومات</h2>
					
						
						<div class="row">
							<div class="col-6">
								<div class="input-group-meta mb-25">
									<label>الاسم الأول</label>
									<input type="text" name="firstname" placeholder="أدخل الاسم الأول">
								</div>
							</div>
							<div class="col-6">
								<div class="input-group-meta mb-25">
									<label>الاسم الأخير</label>
									<input type="text" placeholder="أدخل الاسم الأخير" name="lastname" class="pass_log_id">
								</div>
							</div>
							<div class="col-6">
								<div class="input-group-meta mb-25">
									<label>الدولة</label>
										<select name="country_id" class="form-select form-select-lg mb-6"  >
											<option v-for="country in countries" :key="country.id" v-bind:value="country.id">
											{{country.name}} <span> <img style="height:100px; width:100px" v-bind:src="'https://turkey-cenie.com/public/countries_icons/'+country.icon" alt=""> </span>
											</option>
										</select>
								</div>
							</div>
							
                            <div class="col-6">
								<div class="input-group-meta mb-25">
									<label>الولاية</label>
									<input type="text" name="state" placeholder="أدخل الولاية">
								</div>
							</div>
                             <div class="col-6">
								<div class="input-group-meta mb-25">
									<label>المدينة</label>
									<input type="text" name="city" placeholder="أدخل المدينة">
								</div>
							</div>
                             <div class="col-6">
								<div class="input-group-meta mb-25">
									<label>المنطقة</label>
									<input type="text" name="region" placeholder="أدخل المنطقة">
								</div>
							</div>
							<div class="col-12">
								<div class="input-group-meta mb-25">
									<label>اسم العنوان</label>
									<input type="text" name="address_name" placeholder="أدخل اسم العنوان">
								</div>
							</div>
							<div class="col-12">
								<div class="input-group-meta mb-25">
									<label>العنوان</label>
									<input type="text" name="address" placeholder="أدخل العنوان">
								</div>
							</div>
							<div class="col-6">
								<div class="input-group-meta mb-25">
									<label>رقم الهاتف</label>
									<input type="text" name="telephone" placeholder="أدخل الهاتف">
								</div>
							</div>
							<div class="col-6">
								<div class="input-group-meta mb-25">
									<label>الجوال</label>
									<input type="text" name="mobile" placeholder="أدخل الجوال">
								</div>
							</div>
							<div class="col-6">
								<div class="input-group-meta mb-25">
									<label>اسم الشركة</label>
									<input type="text" name="company_name" placeholder="أدخل اسم الشركة">
								</div>
							</div>
							<div class="col-6">
								<div class="input-group-meta mb-25">
									<label>الرمز البريدي</label>
									<input type="text" name="postcode" placeholder="أدخل الرمز البريدي">
								</div>
							</div>
							<div class="col-6">
								<div class="input-group-meta mb-25">
									<label>الدائرة الضريبية</label>
									<input type="text" name="tax_department" placeholder="أدخل الدائرة الضريبية">
								</div>
							</div>
							<div class="col-6">
								<div class="input-group-meta mb-25">
									<label>الرقم الضريبي</label>
									<input type="text" name="tax_number" placeholder="أدخل الرقم الضريبي">
								</div>
							</div>
							<div class="col-12">
								<button  class="btn-eight w-100 mt-50 mb-40 lg-mt-30 lg-mb-30" type="submit">سجل المعلومات</button>
							</div>
							<div class="col-12">
							</div>
						</div>
					</form>
				</div> <!-- /.form-wrapper -->
			</div> <!-- /.user-data-page -->

</template>

<script>
import { logicalExpression } from '@babel/types';
import axios from 'axios';
import { useRouter } from 'vue-router';
export default {

name: 'AddProfile',
data() {
    return {
      countries: [],
	  message:''
    };
  },

 
     setup(){
        const router=useRouter();
        const submit=async e =>{
		const form =new FormData(e.target);
		const inputs=Object.fromEntries(form.entries());
		
	   await axios.post('profile',inputs,{
		headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
		}
	   })
	   .then(()=>{		
		router.push('/ViewProfile')
	 })
	  .catch((error)=>{
		console.log(error)
	 })
	  }
	  return {
    	submit
	  }
    },
	 
  methods: {
     getData() {   
      axios.get('countries')
     .then((response)=>{	
	  this.countries=response.data.data
	 })
	 .catch((error)=>{
		console.log(error)
	 })
    },
  },

  created() {
    this.getData();
  },
};
</script>