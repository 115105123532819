<template >
<div class="footer-style-four theme-basic-footer">
				<div class="container">
					<div class="inner-wrapper">
						<div class="subscribe-area">
							<div class="row align-items-center">
								<div class="col-md-6">
									<div class="title-style-four sm-pb-20">
										<h4 class="main-title">اشترك الآن ولا تفوت الفرصة</h4>
									</div> <!-- /.title-style-four -->
								</div>
								<div class="col-md-6">
									<div class="subscribe-form">
										<form action="#">
											<input type="email" placeholder="البريد الالكتروني ">
											<button class="ripple-btn tran3s">الاشتراك</button>
										</form>
										<p></p>
									</div> <!-- /.subscribe-form -->
								</div>
							</div>
						</div> <!-- /.subscribe-area -->
					</div> <!-- /.inner-wrapper -->
				</div>
			</div> <!-- /.footer-style-four -->
</template>

<script>
export default {

name: 'NewsLetter',
        
    };

</script>