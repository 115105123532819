<template >
				<div class="fancy-feature-eighteen position-relative pt-200 pb-225 lg-pt-130 md-pt-100 xl-pb-150 lg-pb-100">
				<div class="container">
					<div class="row">
						<div class="col-xl-5 col-lg-6 col-md-7 ms-auto">
							<div class="block-style-two" data-aos="fade-left">
								<div class="title-style-three">
									<div class="sc-title">اكثر من 50 متجر وماركة</div>
									<h2 class="main-title">تسوق من أكبر المتاجر والماركات المعروفة في تركيا</h2>
								</div> <!-- /.title-style-three -->
								<p class="pt-20 pb-25 lg-pb-20">أصبح في متناولك أهم الماركات التركية والمعروفة بمنتجاتها المميزة وذات النوعية الجيدة</p>
								<ul class="style-none list-item color-rev">
									<li>منصات البيع الالكترونية</li>
									<li>معارض ومراكز الماركات العالمية</li>
									<li>مراكز التسوق وبيع التجزئة</li>
								</ul>
								<router-link class="btn-eight mt-50 lg-mt-30" :to="{ name: 'Portfolio' }" role="button">المتاجر</router-link>
							</div> <!-- /.block-style-two -->
						</div>
					</div>
				</div> <!-- /.container -->
				<div class="illustration-holder" data-aos="fade-right">
					<img src="/frontend/images/assets/30930.webp" alt="" >
					<!--- 
					<img src="/frontend/images/assets/ils_14_1.svg" alt="" class="shapes shape-one" data-aos="fade-down">
					<img src="/frontend/images/assets/ils_14_2.svg" alt="" class="shapes shape-two" data-aos="fade-down" data-aos-delay="100">
					<img src="/frontend/images/assets/ils_14_3.svg" alt="" class="shapes shape-three" data-aos="fade-down" data-aos-delay="200">
					<img src="/frontend/images/assets/ils_14_4.svg" alt="" class="shapes shape-four">
					<img src="/frontend/images/assets/ils_14_5.svg" alt="" class="shapes shape-five">
					<img src="/frontend/images/assets/ils_14_6.svg" alt="" class="shapes shape-six">
					<img src="/frontend/images/assets/ils_14_7.svg" alt="" class="shapes shape-seven">
					 -->
				</div> <!-- /.illustration-holder -->
				<div class="shapes oval-one"></div>
				<div class="shapes oval-two"></div>
				<div class="shapes oval-three"></div>
			</div> <!-- /.fancy-feature-eighteen -->
</template>

<script>
export default {

name: 'FeatureOne',
        
    };

</script>