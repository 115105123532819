<template >
<div>
<div class="theme-inner-banner">
				<div class="container">
					<h2 class="intro-title text-center">الخدمات</h2>
					<ul class="page-breadcrumb style-none d-flex justify-content-center">
						<li><a href="index.html">الرئيسية</a></li>
						<li class="current-page">الخدمات</li>
					</ul>
				</div>
				<img src="/frontend/images/shape/shape_38.svg" alt="" class="shapes shape-one">
				<img src="/frontend/images/shape/shape_39.svg" alt="" class="shapes shape-two">
			</div> <!-- /.theme-inner-banner -->

			
			

			<!-- 
			=============================================
				Feature Section Twenty Four
			============================================== 
			-->
			<div class="fancy-feature-twentyFour">
				<div class="container">
					<div class="row gx-xxl-5">
						<div class="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up">
							<div class="block-style-four">
								<div class="icon d-flex align-items-end justify-content-center"><img src="/frontend/images/icon/icon_35.svg" alt=""></div>
								<a href="service-details-V1.html"><h5>Health Care</h5></a>
								<p>Convert data noise to intelligent insights for competitive differentiation.</p>
								<a href="service-details-V1.html" class="more-btn"><img src="/frontend/images/icon/icon_13.svg" alt="" class="tran3s"></a>
							</div> <!-- /.block-style-four -->
						</div>
						<div class="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up" data-aos-delay="100">
							<div class="block-style-four">
								<div class="icon d-flex align-items-end justify-content-center"><img src="/frontend/images/icon/icon_08.svg" alt=""></div>
								<a href="service-details-V1.html"><h5>Predictive Analytics</h5></a>
								<p>Convert data noise to intelligent insights for competitive differentiation.</p>
								<a href="service-details-V1.html" class="more-btn"><img src="/frontend/images/icon/icon_13.svg" alt="" class="tran3s"></a>
							</div> <!-- /.block-style-four -->
						</div>
						<div class="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up" data-aos-delay="200">
							<div class="block-style-four">
								<div class="icon d-flex align-items-end justify-content-center"><img src="/frontend/images/icon/icon_09.svg" alt=""></div>
								<a href="service-details-V1.html"><h5>Data Engineers</h5></a>
								<p>Stay ahead of disruption & exceed customer expectation by implementing predictive analytics solutions.</p>
								<a href="service-details-V1.html" class="more-btn"><img src="/frontend/images/icon/icon_13.svg" alt="" class="tran3s"></a>
							</div> <!-- /.block-style-four -->
						</div>
						<div class="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up" data-aos-delay="100">
							<div class="block-style-four">
								<div class="icon d-flex align-items-end justify-content-center"><img src="/frontend/images/icon/icon_10.svg" alt=""></div>
								<a href="service-details-V1.html"><h5>Deep Learning</h5></a>
								<p>Access tools for deep learning, cloud computing and any scale.</p>
								<a href="service-details-V1.html" class="more-btn"><img src="/frontend/images/icon/icon_13.svg" alt="" class="tran3s"></a>
							</div> <!-- /.block-style-four -->
						</div>
						<div class="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up" data-aos-delay="200">
							<div class="block-style-four">
								<div class="icon d-flex align-items-end justify-content-center"><img src="/frontend/images/icon/icon_11.svg" alt=""></div>
								<a href="service-details-V1.html"><h5>Data Mining</h5></a>
								<p>You can discover hidden opportunities collecting, analyzing or explaining your data in a different way.</p>
								<a href="service-details-V1.html" class="more-btn"><img src="/frontend/images/icon/icon_13.svg" alt="" class="tran3s"></a>
							</div> <!-- /.block-style-four -->
						</div>
						<div class="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up" data-aos-delay="300">
							<div class="block-style-four">
								<div class="icon d-flex align-items-end justify-content-center"><img src="/frontend/images/icon/icon_12.svg" alt=""></div>
								<a href="service-details-V1.html"><h5>Statistical Modeling</h5></a>
								<p>Offer future-ready business applications that can learn & adjust with time.</p>
								<a href="service-details-V1.html" class="more-btn"><img src="/frontend/images/icon/icon_13.svg" alt="" class="tran3s"></a>
							</div> <!-- /.block-style-four -->
						</div>
					</div>
				</div>
			</div> <!-- /.fancy-feature-twentyFour -->
            </div>
</template>

<script>
export default {

name: 'Services',
        
    };

</script>