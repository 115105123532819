<template >

		<div class="main-page-wrapper">

			
			<!-- 
			=============================================
				Theme Inner Banner
			============================================== 
			-->
			<div class="theme-inner-banner">
				<div class="container">
					<h2 class="intro-title text-center">المتاجر</h2>
					<ul class="page-breadcrumb style-none d-flex justify-content-center">
						<li><a href="index.html">الرئيسية</a></li>
						<li class="current-page">المتاجر</li>
					</ul>
				</div>
				<img src="/frontend/images/shape/shape_38.svg" alt="" class="shapes shape-one">
				<img src="/frontend/images/shape/shape_39.svg" alt="" class="shapes shape-two">
			</div> <!-- /.theme-inner-banner -->

			


			
			<!-- 
			=============================================
				Portfolio Gallery Three
			============================================== 
			-->
			<div class="portfolio-gallery-three mt-140 mb-150 lg-mt-90 lg-mb-100">
				<div class="container">
					<ul class="style-none text-center isotop-menu-wrapper g-control-nav-one pb-30 lg-pb-10">

						<li class="is-checked" data-filter="*"  v-on:click="getData()">الكل</li>
						<li v-for="category in categories" :key="category.id"  v-on:click="get_category_stores(category.id)">{{category.name}}</li>


					</ul>
					
					<!-- <div id="isotop-gallery-wrapper" class="grid-3column">
						<div class="grid-sizer "></div> -->
					<div class="contrainer">	
						<div class="row">
					<div v-for="webstore in webstores" :key="webstore.id" class="col-3 ">
						
							
								<div class="portfolio-block-one  mt-40 xs-mt-30">
									<div class="img-meta"><img style="height:250px; width:200px;margin-bottom:10%;"  v-bind:src="'https://turkey-cenie.com/public/uploads/avatars/'+webstore.image" alt="" class="w-100"></div>
									<a   v-on:click="openWindow(webstore.url)"  class="title tran3s d-flex flex-column justify-content-center align-items-center">
									<span  class="pj-name">{{webstore.name}}</span>
									<span class="tag">{{webstore.url}}</span>
								
									</a>
								<div class="hover-state tran3s"><a class="fancybox tran3s" data-fancybox="" title="Click for large view" v-bind:href="'https://turkey-cenie.com/public/uploads/avatars/'+webstore.image" tabindex="0"><i class="bi bi-plus"></i></a></div>
							</div> <!-- /.portfolio-block-one -->
							</div>
						  </div> <!-- /.item -->
					 	</div>		
						</div>
					</div>
					</div>	
				<!-- </div> -->
						  
</template>

<script>
import axios from 'axios';
export default {
name:'Portofolio',
data() {
    return {
      webstores: [],
	  categories:[],
	 
    };
  },

  methods: {
     getData() {
      
      axios.get('webstores')
     .then((response)=>{
		this.webstores=response.data.data
	 })
	 .catch((error)=>{
		console.log(error)
	 })
	
	  axios.get('webstore_category_type')
     .then((response)=>{	
	  this.categories=response.data.data	
	 })
	 .catch((error)=>{
		console.log(error)
	 })

    },
	openWindow(url)
	{
		window.open(url.includes('http')? url: 'http://'+url);
	},
	get_category_stores(id)
	{
		this.webstores=[]
		
	 axios.get('webstores_by_category/'+id)
     .then((response)=>{
		this.webstores=response.data.data
	 })
	 .catch((error)=>{
		console.log(error)
	 })

	}
  },

  created() {
    this.getData();
  },
};
</script>