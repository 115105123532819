<template >
<br><br><br>
<div class="fancy-feature-twenty position-relative mt-160 pb-100 lg-mt-100 lg-pb-70">
				<div class="container">
					<div class="row">
						<div class="col-lg-5">
							<div class="block-style-five pe-xxl-5 me-xxl-5 md-pb-50" data-aos="fade-right">
								<div class="title-style-three">
									<div class="sc-title"></div>
									<h2 class="main-title">الأسئلة الأكثر شيوعاً</h2>
								</div> <!-- /.title-style-three -->
								<p class="pt-20 pb-15">في حال كان لديك أي استفسار آخر تواصل معنا سنكون موجودين دائماً من أجلك</p>
								<a href="contact-us.html" class="btn-eight ripple-btn">اتصل بنا</a>
							</div> <!-- /.block-style-five -->
						</div>

						<div class="col-lg-7" data-aos="fade-left">
							<div class="accordion accordion-style-one" id="accordionOne">
								<div class="accordion-item">
								    <div class="accordion-header" id="headingOne">
								      	<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
								        		ما هي طريقة الدفع؟
								      	</button>
								    </div>
								    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionOne">
								    	<div class="accordion-body">
								        	<p>الموقع يسمح لك بدفع الأجور عن طريق التحويل المالي. </p>
								    	</div>
								    </div>
							  	</div>
							  	<div class="accordion-item">
								    <div class="accordion-header" id="headingTwo">
								      	<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
								        		ما هي المحفظة الإلكترونية?
								      	</button>
								    </div>
								    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionOne">
								      	<div class="accordion-body">
								        	<p>المحفظة هي عبارة عن تطبيق إلكتروني ينظم جميع الحركات المالية وتستخدم للدفع عن طريق الانترنت وتحتوي هذه المحفظة على بيانات الشخص بصيغة مشفرة. </p>
								    	</div>
								    </div>
							  	</div>
							  	<div class="accordion-item">
								    <div class="accordion-header" id="headingThree">
								   		<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
								        		هل يمكن تتبع الشحنة؟
								      	</button>
								    </div>
								    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionOne">
								    	<div class="accordion-body">
								        	<p>نعم من الممكن تتبع الشحن حيث يتم ارسال رقم الشحنة لتتبعها  </p>
								    	</div>
								    </div>
								</div>
								<div class="accordion-item">
								    <div class="accordion-header" id="headingFour">
								   		<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
								       	ما هي المدة التي يمكن أن تبقى فيها البضائع مخزنة في طرودي؟
								      	</button>
								    </div>
								    <div id="collapseFour" class="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionOne">
								    	<div class="accordion-body">
								        	<p>نعم من الممكن تتبع الشحن حيث يتم ارسال رقم الشحنة لتتبعها  </p>
								    	</div>
								    </div>
								</div>
								<div class="accordion-item">
								    <div class="accordion-header" id="headingFive">
								   		<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
								        		كيف يمكن أن أرى البضائع السابقة؟
								      	</button>
								    </div>
								    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionOne">
								    	<div class="accordion-body">
								        	<p>من خلال قائمة طرودي السابقة والتي يتم تجميع معلومات عن الطرود التي تم شحنها </p>
								    	</div>
								    </div>
								</div>
							</div>
						</div>
					</div>
				</div> <!-- /.container -->
				<img src="/frontend/images/shape/shape_36.svg" alt="" class="shapes shape-one">
				<div class="shapes oval-one"></div>
			</div> <!-- /.fancy-feature-twenty -->
</template>

<script>
export default {

name: 'FaqBage',
        
    };

</script>