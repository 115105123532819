<template >
<div class="theme-inner-banner">
				<div class="container">
					<h2 class="intro-title text-center">تواصل معنا اينما كنت </h2>
					<ul class="page-breadcrumb style-none d-flex justify-content-center">
						<li><a href="index.html">الرئيسية</a></li>
						<li class="current-page">اتصل بنا</li>
					</ul>
				</div>
				<img src="images/shape/shape_38.svg" alt="" class="shapes shape-one">
				<img src="images/shape/shape_39.svg" alt="" class="shapes shape-two">
			</div> <!-- /.theme-inner-banner -->

			
			

			<!-- 
			=============================================
				Contact Section One
			============================================== 
			-->
			<div class="contact-section-one mb-170 lg-mb-120">
				<div class="container">
					<div class="row">
						<div class="col-md-4">
							<div class="address-block-two text-center mb-40 sm-mb-20">
								<div class="icon d-flex align-items-center justify-content-center m-auto"><img src="images/icon/icon_17.svg" alt=""></div>
								<h5 class="title">العنوان</h5>
								<p>تركيا / اسطنبول</p>
							</div> <!-- /.address-block-two -->
						</div>
						<div class="col-md-4">
							<div class="address-block-two text-center mb-40 sm-mb-20">
								<div class="icon d-flex align-items-center justify-content-center m-auto"><img src="images/icon/icon_18.svg" alt=""></div>
								<h5 class="title">البريد الالكتروني</h5>
								<p><a href="mailto:905539471094" class="call">info@turkey-cenie.com</a></p>
							</div> <!-- /.address-block-two -->
						</div>
						<div class="col-md-4">
							<div class="address-block-two text-center mb-40 sm-mb-20">
								<div class="icon d-flex align-items-center justify-content-center m-auto"><img src="images/icon/icon_19.svg" alt=""></div>
								<h5 class="title">اتصل بنا مباشرة </h5>
								<p> <a href="https://wa.me/905539471094" class="call">905539471094+</a></p>
							</div> <!-- /.address-block-two -->
						</div>
					</div>
				</div>

				<div class="mt-100 lg-mt-70">
					<div class="container">
						<div class="row gx-xxl-5">
							<div class="col-lg-6 d-flex order-lg-last">
								<div class="form-style-one">
									<h3 class="form-title pb-40 lg-pb-20">ارسال رسالة </h3>
									<form action="inc/contact.php" id="contact-form"  data-toggle="validator">
										<div class="messages"></div>
										<div class="row controls">
											<div class="col-12">
												<div class="input-group-meta form-group mb-30">
													<label>الاسم الكامل*</label>
													<input type="text" placeholder="الرجاء ادخال الاسم كاملا" name="name" required="required" data-error="الاسم مطلوب.">
													<div class="help-block with-errors"></div>
												</div>
											</div>
											
											<div class="col-12">
												<div class="input-group-meta form-group mb-30">
													<label>البريد الالكتروني*</label>
													<input type="email" placeholder="example@mail.com" name="email" required="required" data-error="الايميل مطلوب.">
													<div class="help-block with-errors"></div>
												</div>
											</div>
											<div class="col-12">
												<div class="input-group-meta form-group mb-30">
													<textarea placeholder="رسالتك*" name="message" required="required" data-error="الرجاء ترك رسالة."></textarea>
													<div class="help-block with-errors"></div>
												</div>
											</div>
											<div class="col-12"><button class="btn-eight ripple-btn">ارسال رسالة</button></div>
										</div>
									</form>
								</div> <!-- /.form-style-one -->
							</div>

							<div class="col-lg-6 d-flex order-lg-first">
								<div class="map-area-one mt-10 me-lg-4 md-mt-50">
									<div class="mapouter">
										<div class="gmap_canvas">
											<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d770809.0860158126!2d28.45174621544522!3d41.003964328513874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa7040068086b%3A0xe1ccfe98bc01b0d0!2zxLBzdGFuYnVs!5e0!3m2!1sen!2str!4v1658401170261!5m2!1sen!2str" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
										</div>
									</div>
								</div> <!-- /.map-area-one -->
							</div>
						</div>
					</div>
				</div>
			</div> <!-- /.contact-section-one -->
</template>

<script>
export default {

name: 'ContactUs',
        
    };

</script>