<template >

<Sign-Up-page></Sign-Up-page>

</template>

<script>
import  SignUpPage from '../../components/Pages/SignUp.vue';

export default {
   name: "SignUp",
    components: { 
        SignUpPage,
				    }, 
    };

</script>