<template >
			<div class="hero-banner-five">
				<div class="container">
					<div class="row">
						<div class="col-xxl-6 col-md-7">
							<h1 class="hero-heading">{{ herotittle }}</h1>
							<p class="text-lg mb-50 pt-40 pe-xl-5 md-pt-30 md-mb-40">{{ herodiscrip }}</p>
							<ul class="style-none button-group d-flex align-items-center">
								<li class="me-4"><a href="https://turkey-cenie.com/#/Portfolio" class="demo-btn ripple-btn tran3s">{{ demoaction }}</a></li>
								<li><a class="fancybox video-icon tran3s" data-fancybox="" href="https://www.youtube.com/embed/aXFSJTjVjw0"><i class="fas fa-play"></i></a></li>
							</ul>
						</div>
					</div>
				</div> <!-- /.container -->
				<div class="illustration-holder">
					<img src="/frontend/images/assets/29115.webp" alt="" class="main-illustration ms-auto">
					<!--  
					<img src="/frontend/images/assets/ils_13_1.svg" alt="" class="shapes shape-one">
					<img src="/frontend/images/assets/ils_13_2.svg" alt="" class="shapes shape-two" data-aos="fade-down">
					<img src="/frontend/images/assets/ils_13_2.svg" alt="" class="shapes shape-three" data-aos="fade-down">
					-->
				</div> <!-- /.illustration-holder -->
				<div class="shapes oval-one"></div>
			</div> <!-- /.hero-banner-five -->  
</template>

<script>
export default {

name: 'Banner',
 

data() {
  return {
       herotittle: 'تخلص من معاناة الذهاب إلى الأسواق',
	   herodiscrip: 'تخلص من معاناة الذهاب إلى الأسواق',
	   demoaction: 'تجربة جديدة'
  }
}
        
    };

</script>