<template>
<Master/>
</template>

<script>
import master from './views/pages/Master.vue'
import Master from './views/pages/Master.vue';

export default {
 
 components: { Master }
    
  };

</script>



