<template>
			
					
                          <div class="carousel">
                              <slot></slot>
                                   <button @click="next" class="next rows">&lt;&lt;</button>
                                  <button @click="prev" class="prev rows">>></button>
                           </div>
                    
</template>


<script>
export default {
    name:'Carousel',
    data(){
        return {

        }
    },
    methods:{
        next(){
            this.$emit('next');
        },
        prev()
        {
            this.$emit('prev');
        }
    }
}
</script>

<style>
    .carousel{
        position:relative;
        width:100%;
        margin-left: auto;
        margin-right: auto;
        height:350px;
        overflow: hidden;
        text-align: center;

    }
    .rows{
        position:absolute;
        height:40px;
        width:50x;
        top:calc(50%-20px);
    }
    .next{
        right: 0;
        top:40%;
    }
    .prev{
        left:0;
         top:40%;
    }
</style>