<template >

<Pricing-page></Pricing-page>

</template>

<script>
import PricingPage from '../../components/Pages/Pricing.vue';

export default {
   name: "Pricing",
    components: { 
        PricingPage,
				    }, 
    };

</script>