<template>
<br> <br> <br>
<div class="user-data-page clearfix d-md-flex">
    <div class="illustration-wrapper d-none d-md-flex align-items-center">
        <h3> إضافة طلب طرد جديد</h3>
        <div class="illustration-holder">
            <img src="images/assets/ils_22.svg" alt="" class="illustration m-auto">
        </div>
    </div> <!-- /.illustration-wrapper -->

    <div class="form-wrapper">
        <div class="d-flex justify-content-between align-items-center">
            <div class="logo"><a href="index.html" class="d-block"><img src="images/logo/logo_01.png" alt="" width="131"></a></div>
            <!-- <a href="index.html" class="go-back-button tran3s">الذهاب إلى الرئيسية</a> -->
            <a href="/#/" class="go-back-button tran3s">الذهاب إلى الرئيسية</a>
        </div>

        <h4>معلومات الطرد</h4>

        <div class="row">
            <div class="col-10">
                <div id="response"></div>
                <div class="input-group-meta mb-25">
                    <label>موقع الشراء </label>
                    <!-- <v-select :options="webstoresArr" v-model="website" :value=url :get-option-label="(option) => option.url" /> -->
                      <v-select class="product_id" v-model="website"    name="website" :options="webstores" :value=id label=url></v-select>

                    </div>
            </div>
            <div class="col-10">
                <div class="input-group-meta mb-25">
                    <label>رقم الكود من الموقع</label><br>
                    <input v-model="order_code" class="form-group no-border" placeholder="رقم الكود من الموقع">
                </div>
            </div>
        </div>
        <div class="col-10">
            <div class="input-group-meta mb-25">
                <label>الوصف</label><br>
                <textarea class="text-area" v-model="description" rows="3" placeholder="أضف الوصف هنا"></textarea>
            </div>
        </div>
        <div class="col-10">
            <button v-on:click="PostOrder()" class="btn-eight w-100 mt-50 mb-40 lg-mt-30 lg-mb-30">سجل المعلومات</button>
        </div>
    </div>
</div> <!-- /.form-wrapper -->
</template>

<script>
import {
    logicalExpression
} from '@babel/types';
import {
    useRouter
} from 'vue-router';
import axios from 'axios';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    components: {
        vSelect
    },
    name: 'AddOrderPage',
    data() {
        return {
            website: '',
            user_id: '',
            order_code: '',
            description: '',
            webstores: [],
            webstoresArr:[]
        };
    },
    methods: {
        get_webstores() {
            axios.get("webstores")
                .then((response) => {
                    this.webstores = response.data.data;
                    this.webstores.forEach(element => {
                        this.webstoresArr.push({
                            'id': element.id,
                            'url': element.url,
                            'name': element.name,
                        });
                    });
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        PostOrder() {
            axios.get('user', {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.user_id = response.data.id;
                    const formData = new FormData();
                    this.website=this.website['url'];
                    formData.append('website', this.website);
                    formData.append('user_id', this.user_id);
                    formData.append('order_code', this.order_code);
                    formData.append('description', this.description);
                    axios.post('orders', formData, {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            //  route.push('/my-appointments');
                            console.log(response);
                            document.getElementById("response").innerHTML = "تم إدخال الطلب بنجاح !";
                            document.getElementById('response').style.background = "#e6ccff";
                            setTimeout(() => {
                                document.getElementById("response").innerHTML = "";
                            }, 5000);
                            //window.location.href='/my-appointments/';

                        })
                        .catch((error) => {
                            console.log(error)
                            document.getElementById("response").innerHTML = "حدثت مشكلة في إدخال الطلب الرجاء المحاولة لاحقاً";
                            document.getElementById('response').style.background = "#edb2ad";
                            setTimeout(() => {
                                document.getElementById("response").innerHTML = "";
                            }, 5000);
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    ,
    mounted() {
        this.get_webstores();
    },

    created(){
       
    }
}
</script>
