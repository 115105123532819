<template >
		<div class="counter-section-one mt-140 lg-mt-100">
				<div class="container">
					<div class="inner-container bg-color">
						<div class="row justify-content-center">
							<div class="col-md-4 col-sm-6" data-aos="fade-up">
								<div class="counter-block-one text-center mb-20">
									<div class="main-count"><span class="counter">20</span>mil</div>
									<p>Human labor hours saved <br> with help of AI</p>
								</div> <!-- /.counter-block-one -->
							</div>
							<div class="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
								<div class="counter-block-one text-center mb-20">
									<div class="main-count"><span class="counter">1.3</span>b+</div>
									<p>Generated revenue by <br>AI Solutions</p>
								</div> <!-- /.counter-block-one -->
							</div>
							<div class="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="200">
								<div class="counter-block-one text-center mb-20">
									<div class="main-count">$<span class="counter">15</span>mil+</div>
									<p>Saved operational costs <br>due to AI</p>
								</div> <!-- /.counter-block-one -->
							</div>
						</div>
					</div> <!-- /.inner-container -->
				</div>
			</div> <!-- /.counter-section-one -->
</template>

<script>
export default {

name: 'Skills',
        
    };

</script>