<template>
    <br> <br> <br> 

</template>
<script>
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {

name: 'LogOut',
methods:{
 logout() {
        const router=useRouter();
        axios.post('https://turkey-cenie.com/api/logout',{
		headers:{
			Authorization:'Bearer '+localStorage.getItem('token'),
			Accept:'application/json'
		}
		 })
     .then((response)=>{
		//this.profile=response.data.data
		console.log(response)
	 })
	 .catch((error)=>{
		console.log(error)
		console.log(localStorage.getItem('token'))
	 })
	 localStorage.removeItem('token');
	 router.push('/');
    },
},

  created() {
    this.logout();
  }


    
   

};
</script>